import React, {useContext, useState} from 'react'
import Button from "@mui/material/Button"
import {requestCourse} from "../apiRequests";
import {AuthContext} from "../contexts/AuthContext";
import styles from "../styles/form.module.css";
import {CustomFormControl} from "../components/CustomFormControl";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {IAlert} from "../models/IAlert";
import {Helmet} from 'react-helmet'
import checkmark from "../assets/checkmark.svg";
import Typography from '@mui/material/Typography'

const RequestCourse: React.FC = () => {
    const { user } = useContext(AuthContext);
    const [courseCode, setCourseCode] = useState("")
    const [courseTitle, setCourseTitle] = useState("")
    const [school, setSchool] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
    const [hideSubmit, setHideSubmit] = useState(false)
    const [incorrectField, setIncorrectField] = useState("")
    const [alertState, setAlertState] = useState<IAlert>({
        message: "",
        severity: "success",
        duration: 3000
    })

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const token = await user?.getIdToken();
            await requestCourse(courseCode, courseTitle, school, token!)
            setIsLoading(false)
            setHideSubmit(true)
            setAlertState({
                message: "Request submitted successfully",
                severity: "success",
                duration: 3000
            })
            setIsSnackBarOpen(true)
            setIncorrectField("")
        } catch (error) {
            setIncorrectField(error.fieldName || "")
            setIsLoading(false)
            setAlertState({
                message: error.message,
                severity: "error",
                duration: 6000
            })
            setIsSnackBarOpen(true)
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Request A Course | NoteTaking.Club</title>
            </Helmet>

         <div className={styles.topLevelContainer}>
            <div className={styles.formContainer}>
            {!hideSubmit ? (
                <>
                    <div className={styles.header}>
                        Request Course
                    </div>
                    <div className={styles.fieldContainer}>
                        <div className={styles.fieldRow}>
                            <CustomFormControl isDisabled={isLoading} value={courseCode} setter={setCourseCode} label={"Course Code"}
                                            alertState={alertState} incorrectField={incorrectField}/>

                            <CustomFormControl isDisabled={isLoading} value={courseTitle} setter={setCourseTitle} label={"Course Title"}
                                            alertState={alertState} incorrectField={incorrectField}/>
                        </div>
                        <div className={styles.fieldRow}>
                            <CustomFormControl isDisabled={isLoading} value={school} setter={setSchool} label={"School"}
                                            alertState={alertState} incorrectField={incorrectField}/>
                        </div>
                    </div>
                    <Button disabled={isLoading} variant={"outlined"} className={styles.submitButton}
                            onClick={handleSubmit}>
                        {
                            isLoading ?
                                <CircularProgress size={"24px"} className={styles.loadingWheel}/>
                                :
                                "Submit"
                        }
                    </Button>
                </>
            ): (
                <>
                <div style = {{width: '100%'}}>
                  <img src={checkmark} style = {{width: '150px', margin: 'auto', maxWidth: '100%', display: 'block'}} />
                </div>
                  <Typography align = 'center' variant = "h6" style={{ marginTop: "20px", margin: 'auto' }}>
                    Thank you for requesting a new course!
                  </Typography>
                  <Typography align = 'center' variant = "h6" style={{margin: 'auto' }}>
                    We'll get back to you soon.
                  </Typography>
                </>
            )}
            
                    <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration}
                            onClose={() => setIsSnackBarOpen(false)}>
                        <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity}
                                sx={{width: '100%'}}>
                            {alertState.message}
                        </MuiAlert>
                    </Snackbar>
                </div>
            </div>
        </>
    )
}

export default RequestCourse
