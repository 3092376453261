import React, {useEffect, useState} from 'react';
import {LoginForm} from '../components/LoginForm';
import {SignupForm} from '../components/SignupForm';
import styles from '../styles/form.module.css'
import {useHistory} from "react-router";
import {StyledTab, StyledTabs} from "../components/DashTabs";
import {FacebookLoginButton, GoogleLoginButton} from "react-social-login-buttons";
import { isMobile } from "react-device-detect"
import { signInWithRedirect, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, getAuth } from 'firebase/auth';


export const Auth: React.FC<{isSignup: boolean}> = props => {
    const history = useHistory();
    const [isSignup, setIsSignup] = useState(props.isSignup);
    const googleAuthProvider = new GoogleAuthProvider();
    const facebookAuthProvider = new FacebookAuthProvider();

    function tabNumber(){
        return isSignup ? 1 : 0;
    }

    useEffect(() => {
        setIsSignup(history.location.pathname === "/signup")
    }, [history.location.pathname])

    function handleTabChange(event: React.SyntheticEvent, newValue: number) {
        setIsSignup(newValue === 1);
        history.push(newValue === 0 ? '/login' : '/signup');
    }

    const handleSocialSignIn = async (provider) => {
        try {
            const auth = getAuth();
            if(isMobile){
                await signInWithRedirect(auth, provider)
            } else {
                await signInWithPopup(auth, provider)
            }
            history.push({pathname: `/dashboard/viewed-notes`, state: {
                    alert: {
                        message: "Successfully signed in!",
                        severity: "success",
                        duration: 3000
                    },
                    isSnackBarOpen: true
                }});
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className={styles.topLevelContainer}>
            <div className={styles.authContainer}>
                <div className={styles.tabsContainer}>
                    <StyledTabs value={tabNumber()} onChange={handleTabChange} aria-label="">
                        <StyledTab label="Login" />
                        <StyledTab label="Sign Up" />
                    </StyledTabs>
                </div>
                {isSignup ? <SignupForm /> : <LoginForm />}
            </div>
            <div className={styles.socialContainer}>
                <GoogleLoginButton onClick={() => handleSocialSignIn(googleAuthProvider)}></GoogleLoginButton>
                <FacebookLoginButton style={{}} onClick={() => handleSocialSignIn(facebookAuthProvider)}></FacebookLoginButton>
            </div>
        </div>
    )
}

export default Auth
