import { ThemeProvider } from "@mui/material/styles";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { FirebaseProvider } from "./contexts/FirebaseContext";
import { SearchProvider } from "./contexts/SearchContext";
import reportWebVitals from './reportWebVitals';
import "./styles/fileDrop.css";
import './styles/index.css';
import { NTCTheme } from "./styles/LightTheme";

ReactDOM.render(
    <FirebaseProvider>
        <SearchProvider>
            <ThemeProvider theme={NTCTheme}>
                <AuthProvider>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </AuthProvider>
            </ThemeProvider>
        </SearchProvider>
    </FirebaseProvider>,
    document.getElementById("root")
)

const styleLink = document.createElement("link");
const scriptElement = document.createElement("script");
scriptElement.src = "https://documentcloud.adobe.com/view-sdk/main.js";
styleLink.rel = "stylesheet";
document.head.appendChild(styleLink);
document.head.appendChild(scriptElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
