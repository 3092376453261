import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
const PrivateRoute = ({ component: RouteComponent, checkEmailVerified, ...rest }) => {
	const { user } = useContext(AuthContext);
	return (
		<Route
			{...rest}
			render={(routeProps) => {
					if (user) {
						if (checkEmailVerified === true && user.emailVerified === false){
							console.log("Please verify your email before uploading your notes")
							return (<Redirect
								to={{
									pathname: "/verify",
									state: { prevPath: rest.path },
								}}
							/>)
						
						} else {
							return <RouteComponent {...routeProps} />;
						}
					} else {
						return (
							<Redirect
								to={{
									pathname: "/login",
									state: { prevPath: rest.path },
								}}
							/>
						);
					}
			}}
		/>
	);
};
export default PrivateRoute;
