import { FirebaseApp } from "firebase/app"
import React, { useState } from "react"
import { firebaseApp as app } from "../firebaseSetup"

type ContextProps = {
    firebaseApp: FirebaseApp
}

export const FirebaseContext = React.createContext<Partial<ContextProps>>({})

export const FirebaseProvider = ({ children }: any) => {
    const [firebaseApp] = useState(app)

    return <FirebaseContext.Provider value={{ firebaseApp }}>{ firebaseApp && children}</FirebaseContext.Provider>
}
