import React, { useState, useContext } from "react"
import { AuthContext } from "../contexts/AuthContext"
import { useHistory } from "react-router"
import Button from "@mui/material/Button"
import formStyles from "../styles/form.module.css"
import Typography from "@mui/material/Typography"
import styles from "../styles/verify.module.css"
import { IAlert } from "../models/IAlert"
import MuiAlert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import Helmet from "react-helmet"
import { sendEmailVerification } from "firebase/auth"
import { getAuth, reload } from "firebase/auth"

const VerifyEmail: React.FC = () => {
    const { user } = useContext(AuthContext)
    const history = useHistory()
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
    const [alertState, setAlertState] = useState<IAlert>({
        message: "",
        severity: "success",
        duration: 6000,
    })

    const handleErrors = (error) => {
        setAlertState({
            message: error.message,
            severity: "error",
            duration: 6000,
        })
        setIsSnackBarOpen(true)
        console.log(error)
    }

    const reloadUser = async () => {
        try {
            for (let i = 0; i < 10; i++) {
                const currentUser = await getAuth().currentUser
                if (!currentUser) throw new Error("User is not logged in.")
                await reload(currentUser)
                if (currentUser && currentUser.emailVerified){
                    history.push({
                        pathname: `/dashboard`,
                        state: {
                            alert: {
                                message: "Email verified!",
                                severity: "success",
                                duration: 3000,
                            },
                            isSnackBarOpen: true,
                        },
                    })
                    break
                }
                await new Promise((resolve) => setTimeout(resolve, 500))
            }
        } catch (error) {
            handleErrors(error)
        }
    }

    React.useEffect(() => {
        window.addEventListener("focus", reloadUser)
        reloadUser()
        return () => {
            window.removeEventListener("focus", reloadUser)
        }
    }, [])

    const resendEmailConfirmation = async () => {
        try {
            if (!user) throw new Error("User is null or undefined.")
            await sendEmailVerification(user)
            setAlertState({
                message: "Email confirmation sent.",
                severity: "success",
                duration: 6000,
            })
        } catch (error) {
            handleErrors(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Email Verification | NoteTaking.Club</title>
            </Helmet>
            <div className={formStyles.topLevelContainer}>
                {user ? (
                    <div className={styles.verifyContainer}>
                        <Typography variant="h5">Your email is not verified.</Typography>
                        <Button className={styles.submitButton} color={"secondary"} variant={"contained"} onClick={resendEmailConfirmation}>
                            Resend Email Confirmation
                        </Button>
                    </div>
                ) : (
                    <div className={styles.verifyContainer}>
                        <Typography variant="h5">You are not logged in.</Typography>
                    </div>
                )}
                <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration} onClose={() => setIsSnackBarOpen(false)}>
                    <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity} sx={{ width: "100%" }}>
                        {alertState.message}
                    </MuiAlert>
                </Snackbar>
            </div>
        </>
    )
}

export default VerifyEmail
