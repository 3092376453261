export interface INotes {
    id: string
    authorId: string
    title: string
    description: string
    prof: string
    semester: string
    createdAt: any
    likes: number
    views: number
    courseCode: string
    courseTitle: string
    institution: string
    field: string
    unlisted: boolean
    purchases: number
    dislikes: number
    pageCount: number
    price: number
    totalRevenue: number
    anonymity: boolean
    currency: string
    permanentlyUnlisted: boolean
    averageRating: number
    ratings: number
}

export const priceToString = (price: number) => {
    return (price / 100).toFixed(2)
}


