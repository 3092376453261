export const fees = {
    applicationFee: 0.25,
}

export const authorsCut = (listedPrice: number) => {
    return listedPrice * (1-fees.applicationFee)
}

export const totalCost = (authorsCut: number) => {
    return authorsCut/(1-fees.applicationFee)
}


