import React from "react";
import { Helmet } from "react-helmet";
import styles from "../styles/about.module.css";
import { useHistory } from "react-router-dom";

export const About: React.FC = () => {
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>About | NoteTaking.Club</title>
      </Helmet>

      <div className={styles.topLevelContainer}>
        <div className={styles.aboutLandingTileContainer}>
          <div className={styles.title}>About Us</div>
        </div>

        <div className={styles.flexContainer}>
          <div className={styles.aboutContainer}>
            <div className={styles.subtitle}>Who are we?</div>
            <p>
              NoteTaking.Club is an online note marketplace founded by students,
              for students. On our website, students can both buy and sell
              high-quality course notes.
            </p>
            <p>
              Our mission is to help students learn and earn. As university
              students, we noticed that most note marketplaces are
              subscription-based and provide little to no compensation to note
              sellers. As a matter of fact, we found that the majority of these
              websites profit off of unsuspecting students’ intellectual
              property by convincing them to upload their notes for free. This
              leads to platforms where the notes are low quality and students
              are billed for a subscription service they never wanted in the
              first place. We decided to make a subscription-free note
              marketplace we would actually want to use ourselves.
            </p>
            <p>
              NoteTaking.Club allows students to purchase notes for only the
              courses they need and enables sellers to choose exactly how much
              they are paid per purchase. We sincerely hope by using
              NoteTaking.Club, buyers improve their grades and sellers gain an
              extra revenue stream for themselves.
            </p>
            <p>
              Want to give us some feedback or talk to our team? Send us a
              message{" "}
              <a
                onClick={() => {
                  history.push("/contact");
                }}
                className={styles.aboutLink}
              >
                here
              </a>{" "}
              or at contact@notetaking.club.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
