import React from 'react'
import guy from '../assets/just chilling.jpg'
import {Helmet} from 'react-helmet'


const NotFound: React.FC = () => {

    return(
        <>
            <Helmet>
                    <title>Not Found | NoteTaking.Club</title>
            </Helmet>
            <div>
                <h1>404</h1>
                <img style = {{maxWidth: '100%'}} src={guy}/>
            </div>
        </>
    )
}

export default NotFound