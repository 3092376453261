import Button from "@mui/material/Button"
import React, { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { notesIndex } from "../algoliaSetup"
import AlgoliaLogo from "../assets/search-by-algolia-light-background.svg"
import NotesThumbnail from "../components/NotesThumbnail"
import { AuthContext } from "../contexts/AuthContext"
import { SearchContext } from "../contexts/SearchContext"
import { default as gridStyles, default as styles } from "../styles/dashboardGrid.module.css"

const SearchResults: React.FC = () => {
    const { user } = useContext(AuthContext)
    const { searchObject, setSearchObject, loading, setLoading, setLastQuery, lastQuery } = useContext(SearchContext)
    const [hasMore, setHasMore] = useState(false)
    const hits = searchObject ? searchObject.hits : []

    const displayStyle = (isNone: boolean) => {
        if (!isNone) return { display: "none" }
        else return {}
    }

    useEffect(() => {
        setHasMore(searchObject && searchObject.nbHits > searchObject.hits.length)
    }, [searchObject])

    async function refineNext() {
        try{
            const nextRefinement = await notesIndex.search(searchObject.query, {
                hitsPerPage: 12,
                filters: "unlisted:false AND permanentlyUnlisted:false",
                page: searchObject.page + 1,
            })
            nextRefinement.hits = searchObject.hits.concat(nextRefinement.hits)
            setSearchObject(nextRefinement)
        }catch(error){
            console.log(error)
        }
    }

    async function search(searchQuery: string) {
        try{
            setLoading(true)
            setSearchObject(
                await notesIndex.search(searchQuery, {
                    filters: "unlisted:false AND permanentlyUnlisted:false",
                    hitsPerPage: 12,
                })
            )
            // * have to set it twice because i am very good at programming
            setLastQuery("`")
            setLastQuery("")
            setLoading(false)
        }catch(error){
            console.log(error)
        }
    }

    function clearSearch() {
        search("")
    }

    const getContent = () => {
        // * change true to something else if you want to see the loading screen
        if (true) {
            if (hits.length === 0) {
                return (
                    <div className={styles.noNotesContainer}>
                        <div className={styles.noNotes}>No results.</div>
                        <br />
                        <div className={styles.browseAll} onClick={() => clearSearch()}>Browse All Notes</div>
                    </div>
                )
            } else {
                return (
                    <div className={gridStyles.notesContainer}>
                        {hits.map((hit, i) => (
                            <NotesThumbnail loading={false} notes={Object.assign(hit, { id: hit.objectID })} />
                        ))}
                    </div>
                )
            }
        } else {
            setTimeout(() => {
                return (
                    <div className={styles.noNotesContainer}>
                        <div className={styles.noNotes}>Loading...</div>
                    </div>
                )
            }, 1000)
        }
    }

    return (
        <>
            <Helmet>
                <title>Search | NoteTaking.Club</title>
            </Helmet>

            <div>
                {getContent()}
                <div className={gridStyles.algoliaLogoContainer} style={displayStyle(hits.length > 0)}>
                    {/* <img src={AlgoliaLogo} /> */}
                </div>
                <div className={gridStyles.seeMoreContainer}>
                    <Button onClick={refineNext} className={gridStyles.seeMore} style={displayStyle(hasMore)}>
                        Show more
                    </Button>
                </div>
            </div>
        </>
    )
}

export default SearchResults
