import React, {useContext, useEffect, useState} from 'react'
import {AuthContext} from "../contexts/AuthContext";
import NotesThumbnail from './NotesThumbnail'
import Button from "@mui/material/Button"
import {INotes} from '../models/INotes'
import {getPaginatedNotes} from "../apiRequests";
import styles from "../styles/dashboardGrid.module.css"
import {IAlert} from "../models/IAlert";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";


interface GridProps {
    activeTab: string
}

const DashboardGrid: React.FC<GridProps> = (props) => {
    const { user } = useContext(AuthContext);
    const [notesArr, setNotesArr] = useState<INotes[]>([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [seeMore, setSeeMore] = useState(true)
    const [thumbToken, setThumbToken] = useState("")
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
    const [alertState, setAlertState] = useState<IAlert>({
        message: "",
        severity: "success",
        duration: 6000})

    const skeletonTimestamp = new Date().getTime();

    const skeletonNote:INotes = {
        title: "skeleton",
        description: "skeleton",
        semester: "",
        prof: "",
        createdAt: skeletonTimestamp,
        likes: 0,
        views: 0,
        courseCode: "",
        courseTitle: "",
        field: "",
        institution: "",
        id: "",
        authorId: "",
        unlisted: false,
        purchases: 0,
        dislikes: 0,
        price: 0,
        pageCount: 0,
        totalRevenue: 0,
        anonymity: false,
        currency: "US$",
        permanentlyUnlisted: false,
        averageRating: 0,
        ratings: 0
    }
    const skeletonNotes: INotes[] = Array(8).fill(skeletonNote)

    const handleErrors = (error) => {
        setAlertState({
            message: error.message,
            severity: "error",
            duration: 6000
        })
        setIsSnackBarOpen(true)
        setIsLoaded(true)
        console.log(error)
    }

    useEffect(() => {
        const getFirstNotes = async () => {
            try {

                setSeeMore(false)
                setIsLoaded(false)
                //passing undefined returns the first 8 found.
                const token = await user?.getIdToken()
                setThumbToken(token!)
                if (token) {
                    const newArray = await getPaginatedNotes(undefined, props.activeTab, token)
                    setNotesArr(newArray)
                    setIsLoaded(true)
                    setSeeMore(newArray.length % 12 === 0 && newArray.length !== 0)
                } else {
                    throw new Error("Couldnt get id token. Make sure you are logged in.")
                }
                setIsLoaded(true)
            } catch (error) {
                handleErrors(error)
            }
        }

        /* eslint-disable */
        getFirstNotes()
        /* eslint-disable */
    }, [props.activeTab]);


    const handleSeeMore = async () => {
        try {
            const scrollPosition = window.pageYOffset
            setIsLoaded(false)
            const lastDoc = notesArr[notesArr.length - 1].id
            const token = await user?.getIdToken()
            if (token) {

                var newNotes: INotes[] = await getPaginatedNotes(lastDoc, props.activeTab, token)
                newNotes = notesArr.concat(newNotes)
                setNotesArr(newNotes)
                setIsLoaded(true)
                setSeeMore(newNotes.length % 12 === 0 && newNotes.length !== 0)

                window.scrollTo(0, scrollPosition)
            } else {
                throw new Error("Couldnt get id token. Make sure you are logged in.")
            }
            setIsLoaded(true)
        } catch (error) {
            handleErrors(error)
        }
    }

    function notesType(){
        return props.activeTab.toLowerCase().split("-")[0]
    }

    // ! This component gets re-rendred multiple times which is cringe.
    const getContent = () => {
        if (isLoaded) {
            if (notesArr.length === 0) {
                return <div className={styles.noNotesContainer}><div className={styles.noNotes}>You haven't {notesType()} any notes yet.</div></div>
            } else {
                return (
                    <div className={styles.notesContainer}>
                        {notesArr?.map((notes, i) => <NotesThumbnail notes={notes} loading={false}/>)}
                    </div>

                )
            }
        } else {
            return <div className={styles.notesContainer}>
                {skeletonNotes.map((notes, i) => <NotesThumbnail notes={notes} loading={true}/>)}
            </div>
        }
    }

    function getSeeMoreButton() {
        if (seeMore) {
            return <div className={styles.seeMoreContainer}>
                    <Button onClick={handleSeeMore} className={styles.seeMore}>Show more</Button>
                </div>
        } else {
            return <div></div>
        }
    }

    return (
        <div>
            {getContent()}
            {getSeeMoreButton()}
            <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration} onClose={() => setIsSnackBarOpen(false)}>
                <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity} sx={{ width: "100%" }}>
                    {alertState.message}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}


export default DashboardGrid

