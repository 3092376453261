import React from "react";
import Collapse from "@mui/material/Collapse";
import styles from "../styles/faq.module.css";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import ReactHtmlParser from "react-html-parser"



export interface qanda {
    question: string,
    answer: string
}

export interface category {
    title: string,
    qandas: qanda[]
}

const FAQCategory: React.FC<{ category: category, open: boolean }> = props => {
    const [open, setOpen] = React.useState(props.open);
    const handleClick = () => {
        setOpen(!open);
    };

    const parseAnswer = (answer: string) => {
        if(answer[0] !== "<") return answer
        return ReactHtmlParser(answer)
    }
    
    return (
        <div>
            <Collapse in={open} collapsedSize={40}>
                <div className={styles.category}>
                    <div className={styles.categoryTopBar} onClick={handleClick}>
                        <div className={styles.categoryHeader}>
                            {props.category.title}
                        </div>
                        <IconButton className={styles.iconButton}>
                            {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        </IconButton>
                    </div>
                    <div className={styles.categoryBody}>
                        {props.category.qandas.map((qanda, index) => {
                            return (
                                <div className={styles.qanda} key={index}>
                                    <div className={styles.question}>{qanda.question}</div>
                                    <div className={`${styles.answer} ${styles.linkText}`}>
                                        {parseAnswer(qanda.answer)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Collapse>
            <Divider className={styles.divider}/>
        </div>
    )
}

export default FAQCategory