import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined"
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import MenuBookIcon from "@mui/icons-material/MenuBook"
import MenuOutlined from "@mui/icons-material/MenuOutlined"
import SearchIcon from "@mui/icons-material/Search"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import UploadOutlined from "@mui/icons-material/UploadOutlined"
import AppBar from "@mui/material/AppBar"
import Badge from "@mui/material/Badge"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import InputBase from "@mui/material/InputBase"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { alpha, styled } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import Tooltip from "@mui/material/Tooltip"
import { getAuth, signOut } from "firebase/auth"
import * as React from "react"
import { useContext, useEffect } from "react"
import { useHistory, useLocation } from "react-router"
import { notesIndex } from "../algoliaSetup"
import arrow from "../assets/arrow.svg"
import logoWithText from "../assets/logo with text.svg"
import logo from "../assets/logo.svg"
import { AuthContext } from "../contexts/AuthContext"
import { SearchContext } from "../contexts/SearchContext"
import styles from "../styles/navbar.module.css"
import useWindowDimensions from "./useWindowDimensions"

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("#090030", 0.10),
    "&:hover": {
        backgroundColor: alpha("#090030", 0.20),
    },
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    textAlign: "center",
    "& .MuiInputBase-input": {
        color: theme.palette.primary.main,
        padding: theme.spacing(1, 1, 1, 6),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        width: "100%",
    },
}))
export const Navbar: React.FC = () => {
    const { height, width } = useWindowDimensions()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
    const { user } = useContext(AuthContext)
    const history = useHistory<History>()
    const pageLocation = useLocation()
    const searchRef = React.createRef<HTMLInputElement>()
    const { setSearchObject, lastQuery, setLoading } = useContext(SearchContext)

    useEffect(() => {
        searchRef.current?.setRangeText(lastQuery || "", 0, searchRef.current?.value.length)
    }, [lastQuery])

    useEffect(() => {
        if (history.location.pathname === "/search-results") {
            searchRef.current?.focus()
            searchRef.current?.setSelectionRange(searchRef.current?.value.length, searchRef.current?.value.length)
        }
    }, [history.location.pathname])

    async function search(searchQuery: string) {
        try{
            setLoading(true)
            setSearchObject(
                await notesIndex.search(searchQuery, {
                    filters: "unlisted:false AND permanentlyUnlisted:false",
                    hitsPerPage: 12,
                })
            )
            setLoading(false)
            if (history.location.pathname !== "/search-results") {
                await history.push("/search-results")
            }
        }catch(error){
            console.log(error)
        }
    }

    function handleKeyDown(e, searchQuery: string) {
        if (e.keyCode === 13) {
            search(searchQuery)
        }
    }

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const menuId = "primary-search-account-menu"
    const mobileMenuId = "primary-search-account-menu-mobile"

    const handleSignOut = async () => {
        await signOut(getAuth())
        handleMenuClose()
        console.log("Signed out successfully.")
    }

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    function handleSettingsClick(event: any) {
        history.push("/settings")
        handleMenuClose()
    }

    function handleAccountClick(event: any) {
        handleProfileMenuOpen(event)
    }

    function handleHomeClick() {
        history.push("/")
        handleMenuClose()
    }

    function handleUploadClick() {
        history.push("/upload")
        handleMenuClose()
    }

    function handleDashboardClick() {
        history.push("/dashboard")
        handleMenuClose()
    }

    function handleLoginClick() {
        history.push("/login")
        handleMenuClose()
    }

    function handleSignUpClick() {
        history.push("/signup")
        handleMenuClose()
    }

    const loginButton = (
        <Button variant="text" className={styles.signInButton} onClick={handleLoginClick}>
            Login
        </Button>
    )

    const signUpButton = (
        <Button variant="contained" className={`${styles.signInButton} ${styles.signUpButton}`} onClick={handleSignUpClick}>
            Sign Up
            <img src={arrow} className={styles.arrow} />
        </Button>
    )

    const uploadButton = (
        <Tooltip title="Upload">
            <IconButton aria-label="upload" color="primary" onClick={() => history.push("/upload")} className={styles.button}>
                <Badge>
                    <UploadOutlined />
                </Badge>
            </IconButton>
        </Tooltip>
    )

    const accountButton = (
        <Tooltip title="Account">
            <IconButton aria-label="account" aria-controls={menuId} aria-haspopup="true" onClick={handleAccountClick} color="primary">
                <AccountCircleOutlined />
            </IconButton>
        </Tooltip>
    )

    const dashboardButton = (
        <Tooltip title="My Notes">
            <IconButton aria-label="go to my notes" color="primary" onClick={handleDashboardClick} className={styles.button}>
                <Badge>
                    <MenuBookIcon />
                </Badge>
            </IconButton>
        </Tooltip>
    )

    const hamburgerButton = (
        <IconButton
            className={styles.hamburgerButton}
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="primary"
        >
            <MenuOutlined />
        </IconButton>
    )

    const paperPropsStyle = {
        style: {
            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            borderRadius: "3px",
        },
    }

    const renderMenu = (
        <Menu
            className={styles.subMenuNav}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            PaperProps={paperPropsStyle}
        >
            <MenuItem onClick={handleUploadClick}>Upload</MenuItem>
            <MenuItem onClick={handleDashboardClick}>My Notes</MenuItem>
            <MenuItem onClick={handleSettingsClick}>Settings</MenuItem>
            <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
        </Menu>
    )

    const renderMobileMenu = () => {
        if (user) {
            return (
                <Menu
                    className={styles.subMenuNav}
                    anchorEl={mobileMoreAnchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    id={mobileMenuId}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={isMobileMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem className={styles.menuitem} onClick={handleUploadClick}>
                        <UploadOutlined className={styles.hamburgerButton}></UploadOutlined>
                        <p>Upload</p>
                    </MenuItem>
                    <MenuItem className={styles.menuitem} onClick={handleDashboardClick}>
                        <MenuBookIcon className={styles.hamburgerButton} />
                        <p>My Notes</p>
                    </MenuItem>
                    <MenuItem className={styles.menuitem} onClick={handleSettingsClick}>
                        <SettingsOutlinedIcon className={styles.hamburgerButton}></SettingsOutlinedIcon>
                        <p>Settings</p>
                    </MenuItem>
                    <MenuItem className={styles.menuitem} onClick={handleSignOut}>
                        <LogoutOutlinedIcon className={styles.hamburgerButton}></LogoutOutlinedIcon>
                        <p>Sign Out</p>
                    </MenuItem>
                </Menu>
            )
        } else {
            return (
                <Menu
                    anchorEl={mobileMoreAnchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    id={mobileMenuId}
                    keepMounted
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    open={isMobileMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleLoginClick}>
                        <p>Login</p>
                    </MenuItem>
                    <MenuItem onClick={handleSignUpClick}>
                        <p>Sign Up</p>
                    </MenuItem>
                </Menu>
            )
        }
    }

    return (
        <div>
            <AppBar
                className={`${styles.appbar} ${styles.menu} ${
                    pageLocation.pathname === "/" || pageLocation.pathname === "/about" ? styles.homeAppBar : ""
                }`}
                position="static"
            >
                <Toolbar className={styles.toolbar}>
                    <img
                        draggable="false"
                        className={pageLocation.pathname !== "/" ? styles.logoImg : ""}
                        onClick={handleHomeClick}
                        src={width > 1200 ? logoWithText : logo}
                        alt=""
                    />

                    <Search
                        className={styles.searchbar}
                        style={{ visibility: pageLocation.pathname === "/" ? "hidden" : "visible" }}
                        onSubmit={(event) => {
                            event.preventDefault()
                        }}
                    >
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>

                        <StyledInputBase
                            inputRef={searchRef}
                            autoFocus={pageLocation.pathname === "/search-results"}
                            className={`prompt ${styles.searchInput}`}
                            type="search"
                            onKeyDown={(event) => handleKeyDown(event, searchRef.current?.value ?? "")}
                            onChange={() => search(searchRef.current?.value ?? "")}
                            placeholder="Search…"
                        />
                    </Search>

                    {width >= 900 && (
                        <div className={styles.buttonsContainer}>
                            {user && uploadButton}
                            {user && dashboardButton}
                            {user && accountButton}
                            {!user && loginButton}
                            {!user && signUpButton}
                        </div>
                    )}
                    {width < 900 && <div>{hamburgerButton}</div>}
                </Toolbar>
            </AppBar>
            {renderMobileMenu()}
            {renderMenu}
        </div>
    )
}
