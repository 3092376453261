import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { deleteUser, updateProfile } from "firebase/auth";
import 'firebase/storage';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router";
import { createOnboardingUrl, createStripeLoginLink, getOnboardingStatus, getUser, updateUser } from '../apiRequests';
import { CustomAutoComplete } from "../components/CustomAutoComplete";
import { CustomFormControl } from "../components/CustomFormControl";
import { AuthContext } from "../contexts/AuthContext";
import { FieldError } from "../models/FieldError";
import { IAlert } from "../models/IAlert";
import { IUser } from "../models/IUser";
import formStyles from "../styles/form.module.css";
import styles from '../styles/settings.module.css';
import uploadStyles from '../styles/upload.module.css';

export const Settings: React.FC = () => {
    const {user} = useContext(AuthContext);
    const history = useHistory();
    const [tabNumber, setTabNumber] = useState(0)
    const profileRef = useRef<HTMLDivElement>(null)
    const walletRef = useRef<HTMLDivElement>(null)
    const notificationsRef = useRef<HTMLDivElement>(null)
    const emailRef = useRef<HTMLDivElement>(null)
    const accountRef = useRef<HTMLDivElement>(null)
    const [incorrectField, setIncorrectField] = useState<Array<string>>([""])
    const [isOnboarded, setIsOnboarded] = useState("unknown")
    const [displayName, setDisplayName] = useState("")
    const [institution, setSchool] = useState("")
    const [field, setField] = useState("")
    const [year, setYear] = useState("")
    const [semester, setSemester] = useState("")
    const [isProfileLoading, setIsProfileLoading] = useState(true)
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [alertState, setAlertState] = useState<IAlert>({
        message: "",
        severity: "success",
        duration: 3000
    })
    const [initialUserInfo, setInitialUserInfo] = useState<IUser | undefined>(undefined)
    const domain = process.env.REACT_APP_DOMAIN

    const semesterOptions = [
        {
            value: 'Fall',
            label: 'Fall',
        },
        {
            value: 'Summer',
            label: 'Summer',
        },
        {
            value: 'Winter',
            label: 'Winter',
        },
    ]

    const handleProfileSubmit = async () => {
        try {
            setIsProfileLoading(true)

            const keyValuePairs: Array<{ key: string, value: string }> = []

            if (semester && semester !== "") keyValuePairs.push({ key: "graduationDate", value: semester + " " + year })
            if (institution && institution !== "") keyValuePairs.push({ key: "institution", value: institution })
            if (field && field !== "") keyValuePairs.push({ key: "fieldOfStudy", value: field })


            if (displayName.length > 30) {
                throw new FieldError("display name", "Display name must be less than 30 characters")
            }

            if (keyValuePairs.length > 0) await updateUser(keyValuePairs, await user?.getIdToken()!)

            if(!user) throw new Error("User is undefined") 

            await updateProfile(user, {
                displayName: displayName
            })

            setAlertState({
                message: "Profile has been updated.",
                severity: "success",
                duration: 6000
            })

            if (initialUserInfo !== undefined) {
                setInitialUserInfo({
                    ...initialUserInfo,
                    displayName: displayName,
                    graduationDate: semester + " " + year,
                    school: institution,
                    major: field
                })
            }
            setIsSnackBarOpen(true)
            setIsProfileLoading(false)
            setIncorrectField([""])

        } catch (error) {
            handleErrors(error)
        }
    }

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setIsProfileLoading(true)
                setIsSnackBarOpen(false)
                //Check if user is logged in first
                if (user != null) {
                    //Update placeholders
                    setDisplayName(user?.displayName!)

                    let userInfo = await getUser(await user.getIdToken());
                    setInitialUserInfo(userInfo)
                    setField(userInfo?.major!)
                    setSchool(userInfo?.school!)
                    setYear(userInfo?.graduationDate.split(" ")[1]!)
                    setSemester(userInfo?.graduationDate.split(" ")[0]!)
                    setIsProfileLoading(false)
                }
                setIncorrectField([""])
            } catch (err) {
                console.log(err)
            }

        }
        fetchOnboardingStatus()
        fetchUserData()
    }, [])

    const isProfileSaveDisabled = () => {
        if(isProfileLoading){
            return true
        }
        if (displayName === initialUserInfo?.displayName! && field === initialUserInfo?.major && institution === initialUserInfo?.school
            && year === initialUserInfo?.graduationDate.split(" ")[1]!
            && semester === initialUserInfo?.graduationDate.split(" ")[0]!) {
            return true
        }
        return false
    }

    const tabs = [
        {
            label: "Profile",
            value: 1,
            ref: profileRef,
        },
        {
            label: "Wallet",
            value: 2,
            ref: walletRef,
        },
        {
            label: "Notifications",
            value: 3,
            ref: notificationsRef,
        },
        {
            label: "Email",
            value: 4,
            ref: emailRef,
        },
        {
            label: "Account",
            value: 5,
            ref: accountRef,
        },
    ]

    const erroredFieldName = (error) => {
        let fieldName = error.fieldName || ""
        if (fieldName === "price") {
            return ["buyers pay", "you receive"]
        }
        return [fieldName]
    }

    const handleErrors = (error) => {
        setIncorrectField(erroredFieldName(error))
        setAlertState({
            message: error.message,
            severity: "error",
            duration: 6000
        })
        setIsSnackBarOpen(true)
        setIsProfileLoading(false)
        console.log(error)
    }

    const handleCreateOnboarding = async () => {
        let onboardWindow
        try {
            onboardWindow = window.open('', '_blank')
            onboardWindow.document.write('Loading stripe onboarding...');
            const url = await createOnboardingUrl(await user?.getIdToken()!, domain + "/auto-close", domain + "/refresh")
            if (onboardWindow) onboardWindow.location.href = url.onboardingUrl
        } catch (error) {
            handleErrors(error)
            if (onboardWindow) onboardWindow.close()
        }
    }

    const handleOpenStripeDash = async () => {
        let stripeLoginWindow
        try {
            const token = await user?.getIdToken()
            stripeLoginWindow = window.open('', '_blank')
            stripeLoginWindow.document.write('Loading stripe dashboard...');
            const url = await createStripeLoginLink(token!)
            if (stripeLoginWindow) stripeLoginWindow.location.href = url.stripeLoginLink.url
        } catch (error) {
            handleErrors(error)
            if (stripeLoginWindow) stripeLoginWindow.close()
        }
    }

    async function fetchOnboardingStatus() {
        try {
            const token = await user?.getIdToken()
            const isOnboarded = (await getOnboardingStatus(token!)).detailsSubmitted
            setIsOnboarded(isOnboarded ? "onboarded" : "not onboarded")
        } catch (error) {
            setIsOnboarded("error")
            handleErrors(error)
        }
    }

    const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {

        if (tabs[newValue] && tabs[newValue].ref.current !== null) {
            // @ts-ignore
            const scrollTo = newValue === 0 ? 0 : tabs[newValue].ref.current.getBoundingClientRect().top + window.scrollY
            setTabNumber(newValue)
            window.scroll({
                top: scrollTo,
                left: 0,
            });
        }

    }

    const deleteDialog = () => {
        return (
            <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete your account?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your notes will be unlisted and you will no longer be able to access them.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteDialogOpen(false)}>No</Button>
                    <Button onClick={deleteAccount} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    async function deleteAccount() {
        try{
            if(!user) throw new Error("User is not logged in")
            await deleteUser(user)
            console.log("user has been deleted")

            history.push("/")
        }catch(error){
            if(error.message.includes("recent-login")){
                error.message = "Its been too long since you last logged in. Please log in again to delete your account."
            }
            handleErrors(error)
        }
    }

    const stripeButton = () => {
        if (isOnboarded === "unknown") {
            return <Button
                disabled={true}
                size={"large"} variant={"contained"} color={"secondary"}
                className={uploadStyles.stripeButton}>
                LOADING...
            </Button>
        } else if (isOnboarded === "onboarded") {
            return <Button onClick={handleOpenStripeDash}
                           size={"large"} variant={"contained"} color={"secondary"}
                           className={uploadStyles.stripeButton}>
                VIEW WALLET
            </Button>
        } else if(isOnboarded === "not onboarded") {
            return <Button onClick={handleCreateOnboarding}
                           size={"large"} variant={"contained"} color={"secondary"}
                           className={uploadStyles.stripeButton}>
                Connect to Stripe
            </Button>
        }else{
            return <Button
                disabled={true}
                size={"large"} variant={"contained"} color={"secondary"}
                className={uploadStyles.stripeButton}>
                ERROR
            </Button>
        }
    }

    return (
        <>
            <Helmet>
                <title> Settings | NoteTaking.Club</title>
            </Helmet>

            {deleteDialog()}

            <div className={formStyles.topLevelContainer}>
                <Tabs
                    className={uploadStyles.tabs}
                    value={tabNumber}
                    onChange={handleTabClick}
                    aria-label=""
                    orientation={"vertical"}
                    indicatorColor="secondary"
                >
                    <Tab className={uploadStyles.tab} label="Profile" />
                    <Tab className={uploadStyles.tab} label="Wallet" />
                    <Tab className={uploadStyles.tab} label="Notifications" />
                    <Tab className={uploadStyles.tab} label="Email" />
                    <Tab className={uploadStyles.tab} label="Account" />
                </Tabs>
                <div className={formStyles.formContainer}>
                    <div ref={profileRef} className={`${formStyles.header}`}>
                        Profile
                    </div>

                    <div onClick={() => setTabNumber(0)} className={`${formStyles.fieldContainer} ${styles.fieldContainer}`}>
                        <div className={formStyles.fieldRow}>
                            <CustomFormControl
                                isDisabled={isProfileLoading}
                                value={displayName}
                                setter={setDisplayName}
                                label={"Display Name"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                        </div>
                        <div className={formStyles.fieldRow}>
                            <CustomFormControl
                                isDisabled={isProfileLoading}
                                value={institution}
                                setter={setSchool}
                                label={"Institution"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                            <CustomFormControl
                                isDisabled={isProfileLoading}
                                value={field}
                                setter={setField}
                                label={"Field of Study"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                        </div>
                        <div className={formStyles.fieldRow}>
                            <CustomAutoComplete
                                isDisabled={isProfileLoading}
                                options={semesterOptions}
                                value={semester}
                                setter={setSemester}
                                label={"Graduation Semester"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                            <CustomFormControl
                                isDisabled={isProfileLoading}
                                value={year}
                                setter={setYear}
                                label={"Graduation Year"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                        </div>
                    </div>

                    <Button
                        disabled={isProfileSaveDisabled()}
                        variant={"outlined"}
                        onClick={handleProfileSubmit}
                        className={`${formStyles.submitButton} ${uploadStyles.submitButton}`}
                    >
                        {isProfileLoading ? <CircularProgress size={"24px"} className={formStyles.loadingWheel} /> : "SAVE"}
                    </Button>

                    <div ref={walletRef} className={`${formStyles.header} ${uploadStyles.header} ${styles.walletHeader}`}>
                        Wallet
                    </div>

                    <div onClick={() => setTabNumber(1)} className={formStyles.subtitle}>
                        <span>You only need to connect a stripe account if you intend on selling notes. To learn more about payment,</span>
                        &nbsp;
                        <span
                            className={styles.linkText}
                            onClick={() =>
                                history.push({
                                    pathname: `/FAQ`,
                                    state: {
                                        openCategories: ["Selling"],
                                    },
                                })
                            }
                        >
                            click here.
                        </span>
                    </div>

                    <div onClick={() => setTabNumber(2)} className={`${formStyles.fieldContainer} ${uploadStyles.fieldContainer}`}>
                        {stripeButton()}
                    </div>

                    <div ref={notificationsRef} className={`${formStyles.header} ${uploadStyles.header} ${styles.walletHeader}`}>
                        Notifications
                    </div>
                    <div className={formStyles.subtitle}>
                        <span>Notifications aren't available yet.</span>
                    </div>

                    <div ref={emailRef} className={`${formStyles.header} ${uploadStyles.header} ${styles.walletHeader}`}>
                        Email
                    </div>
                    <div className={formStyles.subtitle}>
                        <span>Email settings aren't available yet.</span>
                    </div>

                    <div ref={accountRef} className={`${formStyles.header} ${uploadStyles.header} ${styles.walletHeader}`}>
                        Account
                    </div>

                    <div onClick={() => setTabNumber(5)}>
                        <Button
                            onClick={() => {
                                history.push("/recover")
                            }}
                            className={`${uploadStyles.stripeButton} ${styles.changePasswordButton}`}
                            size={"large"}
                            variant={"contained"}
                            color={"primary"}
                        >
                            Change Password
                        </Button>

                        <br></br>

                        <Button
                            onClick={() => setIsDeleteDialogOpen(true)}
                            className={`${uploadStyles.stripeButton} ${styles.deleteAccountButton}`}
                            size={"large"}
                            variant={"contained"}
                            color={"error"}
                        >
                            Delete Account
                        </Button>
                    </div>

                    {/*<div onClick={() => setTabNumber(3)}>
                        <div className={formStyles.checkboxContainer}>
                            <Checkbox onChange={() => setIsNotifForwardingChecked(!isNotifForwardingChecked)}/>
                            <div>
                                <span
                                    className={styles.checkboxText}>Forward all my website notifications to my email.</span>
                            </div>
                        </div>
                        <div className={formStyles.checkboxContainer}>
                            <Checkbox onChange={() => setIsNewsletterChecked(!isNewsletterChecked)}/>
                            <div>
                                <span className={styles.checkboxText}>Newsletter</span>
                            </div>
                        </div>
                        <div className={formStyles.checkboxContainer}>
                            <Checkbox onChange={() => setIsPromotionsChecked(!isPromotionsChecked)}/>
                            <div>
                                <span className={styles.checkboxText}>Promotions</span>
                            </div>
                        </div>
                    </div>*/}

                    <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration} onClose={() => setIsSnackBarOpen(false)}>
                        <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity} sx={{ width: "100%" }}>
                            {alertState.message}
                        </MuiAlert>
                    </Snackbar>
                </div>
            </div>
        </>
    )
}