import TextField from "@mui/material/TextField";
import styles from "../styles/form.module.css";
import React from "react";
import {IAlert} from "../models/IAlert";
import LinearProgress from "@mui/material/LinearProgress";

export const CustomMultiline: React.FC<{isDisabled: boolean, value: string, setter: any, label: string, alertState: IAlert, incorrectField: string | Array<string>, className?: string,
    helperText?: string, rows?: number, charLimit?: number}> = (props) => {

    const [isOverflowed, setIsOverflowed] = React.useState(false);

    const isErrored = () =>{
        return (props.incorrectField.includes(props.label.toLocaleLowerCase()))
    }

    const helperText = () =>{
        if(isErrored()){
            return props.alertState.message
        }else{
            return props.helperText ? props.helperText : null
        }
    }

    const progress = () =>{
        if(props.charLimit){
            return (props.value.length / props.charLimit) * 100
        }
        return 0
    }

    const setter = (value: string) =>{
        if(props.charLimit && value.length > props.charLimit){
            setIsOverflowed(true)
        }else{
            setIsOverflowed(false)
        }
        props.setter(value)
    }

    return (
        <div className={`${styles.field} ${props.className}`}>
            {
                false &&
                <LinearProgress color={"info"} className={styles.wordCountProgress} variant="determinate" value={progress()} />
            }
            <TextField
                disabled={props.isDisabled}
                error={isErrored() || isOverflowed}
                multiline
                rows={props.rows ? props.rows : 4}
                variant="filled"
                helperText={helperText()}
                label={props.label}
                value={props.value}
                onChange={(e) => {
                    setter(e.target.value);
                }}
                className={`${styles.field} ${props.className}`}
            />
        </div>
    )
}

