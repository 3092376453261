import React, { useState, useContext, useEffect } from "react";
import styles from "../styles/form.module.css";
import { CustomFormControl } from "../components/CustomFormControl";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IAlert } from "../models/IAlert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AuthContext } from "../contexts/AuthContext";
import { addContactMessage, addContactMessageAuth } from "../apiRequests";
import paperAirplane from "../assets/paperAirplane.svg";
import { Helmet } from "react-helmet";

export const Contact: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hideSubmit, setHideSubmit] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [incorrectField, setIncorrectField] = useState("");
  const [alertState, setAlertState] = useState<IAlert>({
    message: "",
    severity: "success",
    duration: 3000,
  });

  useEffect(() => {
    if (user) {
      if (user.displayName) setName(user.displayName);
      if (user.email) setEmail(user.email);
    }
  }, []);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (user) {
        const token = await user?.getIdToken();
        await addContactMessageAuth(name, email, message, token);
      } else {
        await addContactMessage(name, email, message);
      }
      setAlertState({
        message: "Successfully sent message!",
        severity: "success",
        duration: 3000,
      });

      setIsLoading(false);
      setIsSnackBarOpen(true);
      setHideSubmit(true);
      setIncorrectField("");
    } catch (error) {
      setIncorrectField(error.fieldName || "");
      setIsLoading(false);
      setAlertState({
        message: error.message,
        severity: "error",
        duration: 6000,
      });
      setIsSnackBarOpen(true);
      console.log(error);
    }
  };

  return (
    <>
    <Helmet>
        <title>Contact Us | NoteTaking.Club</title>
    </Helmet>
      <div className={styles.topLevelContainer}>
        <div className={styles.formContainer}>
          {!hideSubmit ? (
            <>
              <div className={`${styles.header}`}>
                Contact Us
              </div>
              <div className={styles.fieldContainer}>
                <div className={styles.fieldRow}>
                  <CustomFormControl
                    isDisabled={isLoading}
                    value={name}
                    setter={setName}
                    label={"Name"}
                    alertState={alertState}
                    incorrectField={incorrectField}
                    isMultiLine={false}
                    className={styles.wideField}
                  />
                  <CustomFormControl
                    isDisabled={isLoading}
                    value={email}
                    setter={setEmail}
                    label={"Email"}
                    alertState={alertState}
                    incorrectField={incorrectField}
                    isMultiLine={false}
                    className={styles.wideField}
                    type = {'email'}
                  />
                </div>
              
                <div className={styles.fieldRow}>
                  <CustomFormControl
                    isDisabled={isLoading}
                    value={message}
                    setter={setMessage}
                    label={"Message"}
                    alertState={alertState}
                    incorrectField={incorrectField}
                    isMultiLine={true}
                    className={`${styles.wideField}`}
                  />
                </div>
              </div>

              <Button
                disabled={isLoading}
                hidden={hideSubmit}
                variant={"outlined"}
                className={styles.submitButton}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <CircularProgress
                    size={"24px"}
                    className={styles.loadingWheel}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </>
          ) : (
            <>
            <div style = {{width: '100%'}}>
              <img src={paperAirplane} style = {{width: '150px', margin: 'auto', maxWidth: '100%', display: 'block'}} />
            </div>
              
              
              <Typography align = 'center' variant = "h6" style={{ marginTop: "20px", margin: 'auto' }}>
                Thanks for reaching out!
              </Typography>
              <Typography align = 'center' variant = "h6" style={{margin: 'auto' }}>
                We'll get back to you soon.
              </Typography>
            </>
          )}

          <Snackbar
            open={isSnackBarOpen}
            autoHideDuration={alertState.duration}
            onClose={() => setIsSnackBarOpen(false)}
          >
            <MuiAlert
              onClose={() => setIsSnackBarOpen(false)}
              severity={alertState.severity}
              sx={{ width: "100%" }}
            >
              {alertState.message}
            </MuiAlert>
          </Snackbar>
        </div>
      </div>
    </>
  );
};
