import ErrorOutline from "@mui/icons-material/ErrorOutline"
import FileDownloadDone from "@mui/icons-material/FileDownloadDone"
import UploadFile from "@mui/icons-material/UploadFile"
import MuiAlert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import Link from "@mui/material/Link"
import Snackbar from "@mui/material/Snackbar"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { getAnalytics, logEvent } from "firebase/analytics"
import { getMetadata, getStorage, ref, updateMetadata, uploadBytes } from "firebase/storage"
import React, { useContext, useEffect, useRef, useState } from "react"
import { FileDrop } from "react-file-drop"
import { Helmet } from "react-helmet"
import { useHistory } from "react-router"
import { useParams } from "react-router-dom"
import {
  createNotes,
  createOnboardingUrl,
  deleteNotes,
  getCourseFieldIds,
  getInstitutionIds,
  getNotes,
  getOnboardingStatus,
  updateNotes
} from "../apiRequests"
import { authorsCut as calculateAuthorsCut, totalCost } from "../ApplicationFeeUtils"
import { CustomAutoComplete } from "../components/CustomAutoComplete"
import { CustomFormControl } from "../components/CustomFormControl"
import { CustomMultiline } from "../components/CustomMultiline"
import { AuthContext } from "../contexts/AuthContext"
import { FieldError } from "../models/FieldError"
import { IAlert } from "../models/IAlert"
import { INotes, priceToString } from "../models/INotes"
import { KeyValuePairs } from "../models/KeyValuePairs"
import formStyles from "../styles/form.module.css"
import styles from "../styles/upload.module.css"

export const Upload: React.FC<{ isEdit: boolean }> = (props) => {
    interface option {
        label: string
        value: string
    }

    const [notesObj, setNotesObj] = useState<INotes>()
    const { user } = useContext(AuthContext)
    const history = useHistory()
    const [semester, setSemester] = useState("")
    const [year, setYear] = useState("")
    const [prof, setProf] = useState("")
    const [title, setTitle] = useState("")
    const [authorsCut, setAuthorsCut] = useState("")
    const [price, setPrice] = useState("")
    const [description, setDescription] = useState("")
    const [PDF, setPDF] = useState<undefined | File>(undefined)
    const [firstPage, setFirstPage] = useState("")
    const [lastPage, setLastPage] = useState("")
    const [thumbnailPage, setThumbnailPage] = useState("")
    const [institutionArray, setInstitutionArray] = useState<option[]>([])
    const [courseFieldArray, setCourseFieldArray] = useState<option[]>([])
    const [courseCode, setCourseCode] = useState("")
    const [courseField, setCourseField] = useState("")
    const [courseTitle, setCourseTitle] = useState("")
    const [institution, setInstitution] = useState("")
    const [isLoading, setIsLoading] = useState(props.isEdit)
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
    const [incorrectField, setIncorrectField] = useState<Array<string>>([""])
    const [currency, setCurrency] = useState("")
    const [tosChecked, setTosChecked] = useState(false)
    const [anonymityChecked, setAnonymityChecked] = useState(false)
    const inputFile = useRef<HTMLInputElement>(null)
    const storage = getStorage()
    const sRef = ref(storage)
    const [alertState, setAlertState] = useState<IAlert>({
        message: "",
        severity: "success",
        duration: 3000,
    })
    const [pageCount, setPageCount] = useState(0)
    const [tabNumber, setTabNumber] = useState(0)
    const detailsRef = useRef<HTMLDivElement>(null)
    const fileUploadRef = useRef<HTMLDivElement>(null)
    const previewRef = useRef<HTMLDivElement>(null)
    const paymentRef = useRef<HTMLDivElement>(null)
    const submitRef = useRef<HTMLDivElement>(null)
    const [isDocumentEdit, setIsDocumentEdit] = useState(false)
    const [isMetadataEdit, setIsMetadataEdit] = useState(false)
    const [isOnboarded, setIsOnboarded] = useState(false)
    const analytics = getAnalytics()

    const onFocus = () => {
        if(isOnboarded === false) {
            fetchOnboardingStatus()
        }
    }

    const { id } = useParams<ParamTypes>()

    interface ParamTypes {
        id: string
    }

    const semesterOptions = [
        {
            label: "Fall",
            value: "Fall",
        },
        {
            label: "Summer",
            value: "Summer",
        },
        {
            label: "Winter",
            value: "Winter",
        },
    ]

    const currencyOptions = [
        {
            value: "CA$",
            label: "CA$",
        },
        {
            value: "US$",
            label: "US$",
        },
    ]

    const tabs = [
        {
            label: "Details",
            value: 1,
            ref: detailsRef,
        },
        {
            label: "File Upload",
            value: 2,
            ref: fileUploadRef,
        },
        {
            label: "Preview",
            value: 3,
            ref: previewRef,
        },
        {
            label: "Payment",
            value: 4,
            ref: paymentRef,
        },
        {
            label: "Submit",
            value: 5,
            ref: submitRef,
        },
    ]

    const erroredFieldName = (error) => {
        let fieldName = error.fieldName || ""
        if (fieldName === "Price") {
            return ["Buyers pay", "You receive"]
        }
        return [fieldName]
    }

    const handleErrors = (error) => {
        setIncorrectField(erroredFieldName(error))
        setAlertState({
            message: error.message,
            severity: "error",
            duration: 6000,
        })
        setIsSnackBarOpen(true)
        setIsLoading(false)
        console.log(error)
    }

    const handleCreateOnboarding = async () => {
        let onboardWindow
        const domain = process.env.REACT_APP_DOMAIN
        try {
            if (analytics) {
                logEvent(analytics, "User initiated onboarding.", [{ key: "userId", value: user?.uid }])
            }

            onboardWindow = window.open("", "_blank")
            onboardWindow.document.write("Loading stripe onboarding...")
            const url = await createOnboardingUrl(await user?.getIdToken()!, domain + "/auto-close", domain + "/refresh")
            if (onboardWindow) onboardWindow.location.href = url.onboardingUrl
        } catch (error) {
            handleErrors(error)
            if (onboardWindow) onboardWindow.close()
        }
    }

    const fetchData = async () => {
        try {
            const token = await user?.getIdToken()
            const pdfRef = ref(storage, `notes/${user?.uid}/${id}/full_document.pdf`)
            const metadata = await getMetadata(pdfRef)


            const result: INotes = await getNotes(id, token || "")
            setTitle(result.title)
            setCourseCode(result.courseCode)
            setCourseTitle(result.courseTitle)
            setInstitution(result.institution)
            setCourseField(result.field)
            setSemester(result.semester.split(" ")[0])
            setYear(result.semester.split(" ")[1])
            setProf(result.prof)
            setDescription(result.description)
            setPageCount(result.pageCount)
            handleListedPriceChange(priceToString(result.price))
            setAnonymityChecked(result.anonymity)
            setCurrency(result.currency)
            setNotesObj(result)

            if(!metadata.customMetadata) throw new Error("Couldnt find preview data.")

            setFirstPage(metadata.customMetadata.firstPage)
            setLastPage(metadata.customMetadata.lastPage)
            setThumbnailPage(metadata.customMetadata.thumbnailPage)

            setIsLoading(false)
        } catch (error) {
            handleErrors(error)
        }
    }

    async function fetchOnboardingStatus() {
        try {
            const token = await user?.getIdToken()
            const isOnboarded = (await getOnboardingStatus(token!)).detailsSubmitted
            setIsOnboarded(isOnboarded)
        } catch (error) {
            handleErrors(error)
        }
    }

    function sendUploadPageVisitedEvent() {
      try {
        if (analytics) {
          logEvent(analytics, "User visisted the upload page.", [{ key: "userId", value: user?.uid }])
        }
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
        window.addEventListener("focus", onFocus)


        sendUploadPageVisitedEvent()
        fetchOnboardingStatus()
        fetchInstitutions("")
        fetchCourseFields("")

        if (props.isEdit) {
            fetchData()
        }


        
        return () => {
            window.removeEventListener("focus", onFocus)
        }
    }, [])

    function countPages(file: File) {
        const reader = new FileReader()
        const fileInfo = { file }
        if (fileInfo) {
            reader.readAsBinaryString(file)
            reader.onloadend = () => {
                if (typeof reader.result === "string") {
                    setPageCount(reader.result.match(/\/Type[\s]*\/Page[^s]/g)?.length || 0)
                }
            }
        }
    }

    const handlePDFDrop = async (event) => {
        try {

            const files = event.target ? event.target.files : event
            if (files) {
                let file = files[0]
                if (file.type !== "application/pdf") {
                    throw new FieldError("PDF", "File must be a PDF")
                }
                if (file.size > 50000000) {
                    throw new FieldError("PDF", "File size must be less than 50MB")
                }

                setPDF(file)
                if (incorrectField.includes("PDF")) {
                    setIncorrectField([""])
                }
                countPages(file)
            } else {
                setPDF(undefined)
                throw new FieldError("PDF", "Invalid selected file.")
            }
        } catch (error) {
            handleErrors(error)
        }
    }

    const validatePageNumbers = () => {
        var reg = new RegExp("^[0-9]+$")
        if (!reg.test(firstPage)) {
            throw new FieldError("first page of preview", "Invalid page number.")
        }
        if (!reg.test(lastPage)) {
            throw new FieldError("last page of preview", "Invalid page number.")
        }
        if (!reg.test(thumbnailPage)) {
            throw new FieldError("thumbnail page", "Invalid page number.")
        }
    }

    // disgusting function, its supposed to return the notes object page count if the person uploaded a pdf but then
    // decided not to use it.
    const actualPageCount = () => {
        if (props.isEdit) {
            if (isDocumentEdit) {
                return pageCount
            } else {
                return notesObj?.pageCount || 0
            }
        } else {
            return pageCount
        }
    }

    const validateFirstPage = (firstPageValue) => {
        const firstPageNum = parseInt(firstPageValue)
        const lastPageNum = parseInt(lastPage)
        if (firstPageNum > actualPageCount()) {
            throw new FieldError("first page of preview", "Page number must be less than page count.")
        } else if (firstPageNum < 1) {
            throw new FieldError("first page of preview", "Page number must be greater than 0.")
        } else if (lastPage !== "" && firstPageNum > lastPageNum) {
            throw new FieldError("first page of preview", "Page number must be less than last page.")
        }
    }

    const validateLastPage = (lastPageValue) => {
        const lastPageNum = parseInt(lastPageValue)
        const firstPageNum = parseInt(firstPage)
        if (lastPageNum > actualPageCount()) {
            throw new FieldError("last page of preview", "Page number must be less than page count.")
        } else if (lastPageNum < 1) {
            throw new FieldError("last page of preview", "Page number must be greater than 0.")
        } else if (firstPage !== "" && lastPageNum < firstPageNum) {
            throw new FieldError("last page of preview", "Page number must be greater than first page.")
        }
    }

    const validateThumbnailPage = (thumbnailPageValue) => {
        const thumbnailPageNum = parseInt(thumbnailPageValue)
        if (thumbnailPageNum > actualPageCount()) {
            throw new FieldError("thumbnail page", "Page number must be less than page count.")
        } else if (thumbnailPageNum < 1) {
            throw new FieldError("thumbnail page", "Page number must be greater than 0.")
        }
    }

    const validatePDF = () => {
        if (!PDF) {
            throw new FieldError("PDF", "Please upload a PDF.")
        }
    }

    const validateCheckFields = () => {
        if (!tosChecked) {
            throw new FieldError("terms of service", "Please agree to the terms of service.")
        }
    }

    const handleSubmit = async () => {
        try {
            setIsLoading(true)

            if (isDocumentEdit || !props.isEdit) {
                validatePDF()
            }

            if (isMetadataEdit || !props.isEdit) {
                validatePageNumbers()
                validateFirstPage(firstPage)
                validateLastPage(lastPage)
                validateThumbnailPage(thumbnailPage)
            }

            if (!props.isEdit) {
                validateCheckFields()
            }

            const uid = (await user?.uid) || ""
            const token = await user?.getIdToken()

            const isOnboarded = (await getOnboardingStatus(token!)).detailsSubmitted
            if (!isOnboarded && parseFloat(price) !== 0) {
                throw new Error("Please complete stripe onboarding so that you can get paid.")
            }

            const keyValuePairs: KeyValuePairs = []
            keyValuePairs.push({ key: "courseCode", value: courseCode })
            keyValuePairs.push({ key: "courseTitle", value: courseTitle })
            keyValuePairs.push({ key: "institution", value: institution })
            keyValuePairs.push({ key: "field", value: courseField })
            keyValuePairs.push({ key: "prof", value: prof })
            keyValuePairs.push({ key: "semester", value: semester + " " + year })
            keyValuePairs.push({ key: "title", value: title })
            keyValuePairs.push({ key: "description", value: description })
            keyValuePairs.push({ key: "price", value: Math.round(parseFloat(price) * 100) })
            keyValuePairs.push({ key: "currency", value: currency })
            keyValuePairs.push({ key: "anonymity", value: anonymityChecked })
            let notesId = ""

            if (props.isEdit) {
                await updateNotes(id, keyValuePairs, token!)
                notesId = id
            } else {
                let result = await createNotes(title, keyValuePairs, token!)
                notesId = result.notesId
            }

            try {
                const pdfRef = ref(storage, `notes/${uid}/${notesId}/full_document.pdf`)
                if (isDocumentEdit || !props.isEdit) {
                    if (!PDF) throw new Error("PDF document is undefined.")
                    await uploadBytes(pdfRef, PDF)
                }

                if (isMetadataEdit || isDocumentEdit || !props.isEdit) {
                    let newMetadata = {
                        customMetadata: {
                            firstPage: firstPage,
                            lastPage: lastPage,
                            thumbnailPage: thumbnailPage,
                        },
                    }

                    await updateMetadata(pdfRef, newMetadata)
                }
            } catch (error) {
                if (!props.isEdit) {
                    //clean up document if file upload fails
                    await deleteNotes(notesId, token!)
                    throw new Error("Error uploading notes. Make sure your file is a PDF document and under 50MB.")
                }

                throw error
            }

            setIncorrectField([""])
            setIsLoading(false)

            if (props.isEdit) {
                if (isDocumentEdit || isMetadataEdit) {
                    history.push({
                        pathname: `/notes/${id}`,
                        state: {
                            alert: {
                                message: "Processing preview...",
                                severity: "warning",
                                duration: 6000,
                            },
                            isSnackBarOpen: true,
                        },
                    })
                } else {
                    history.push({
                        pathname: `/notes/${id}`,
                        state: {
                            alert: {
                                message: "Notes edited successfully.",
                                severity: "success",
                                duration: 6000,
                            },
                            isSnackBarOpen: true,
                        },
                    })
                }
            } else {

                if (analytics) {
                    logEvent(analytics, "User uploaded notes.", [{ key: "userId", value: user?.uid }, { key: "notesId", value: notesId }])
                }
                history.push(`/notes/${notesId}`)
            }
        } catch (error) {
            handleErrors(error)
        }
    }

    const fetchInstitutions = async (queryString) => {
        try {
            let institutionIds = (await getInstitutionIds(queryString)).institutionIds
            const institutionOptions: option[] = institutionIds.map((courseId) => {
                return { label: courseId, value: courseId }
            })
            setInstitutionArray(institutionOptions)
        } catch (error) {
            handleErrors(error)
        }
    }

    const fetchCourseFields = async (queryString) => {
        try {
            let courseFieldIds = (await getCourseFieldIds(queryString)).courseFieldsIds
            const courseFieldOptions: option[] = courseFieldIds.map((courseFieldId) => {
                return { label: courseFieldId, value: courseFieldId }
            })
            setCourseFieldArray(courseFieldOptions)
        } catch (error) {
            handleErrors(error)
        }
    }

    const isSaveDisabled = () => {
        if (isLoading) {
            return true
        }
        return false
    }

    const handleListedPriceChange = (enteredPrice: string) => {
        setPrice(enteredPrice)
        if (enteredPrice === "" || isNaN(parseFloat(enteredPrice))) {
            setAuthorsCut("")
        } else {
            let listedPrice = parseFloat(enteredPrice)
            let calculatedAuthorsCut = calculateAuthorsCut(listedPrice)
            setAuthorsCut(calculatedAuthorsCut.toFixed(2).toString())
        }
    }

    const handleAuthorsCutChange = (enteredPrice: string) => {
        setAuthorsCut(enteredPrice)
        if (enteredPrice === "" || isNaN(parseFloat(enteredPrice))) {
            setPrice("")
        } else {
            let authorsPrice = parseFloat(enteredPrice)
            let calculatedListedPrice = totalCost(authorsPrice)
            setPrice(calculatedListedPrice.toFixed(2).toString())
        }
    }

    const handleFileUploadClick = () => {
        // `current` points to the mounted file input element
        if (inputFile.current) {
            inputFile.current.click()
        }
    }

    const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (tabs[newValue] && tabs[newValue].ref.current !== null) {
            // @ts-ignore
            const scrollTo = newValue === 0 ? 0 : tabs[newValue].ref.current.getBoundingClientRect().top + window.scrollY
            setTabNumber(newValue)
            window.scroll({
                top: scrollTo,
                left: 0,
            })
        }
    }

    function displaySelectedFileMessage() {
        if (incorrectField.includes("PDF")) {
            return (
                <div className={"fileDropperContentsContainer"}>
                    <ErrorOutline sx={{ fontSize: 50 }} className={styles.uploadIcon}></ErrorOutline>
                    <Typography variant={"h5"} color={"text.primary"}>
                        File selection failed.
                    </Typography>
                    <Typography variant={"caption"} color={"text.secondary"}>
                        {alertState.message}
                    </Typography>
                </div>
            )
        } else {
            return (
                <div className={"fileDropperContentsContainer"}>
                    <FileDownloadDone sx={{ fontSize: 50 }} className={styles.uploadIcon}></FileDownloadDone>
                    <div className={styles.fileNameContainer}>
                        <Typography variant={"h5"} color={"text.primary"} className={styles.fileName}>
                            {PDF?.name}
                        </Typography>
                        <span>
                            <Typography variant={"h6"} color={"text.primary"}>
                                ({pageCount} pages)
                            </Typography>
                        </span>
                    </div>
                    <Typography variant={"caption"} color={"text.secondary"}>
                        File selected.
                    </Typography>
                </div>
            )
        }
    }

    const fileDropper = (
        <FileDrop onDrop={handlePDFDrop} onTargetClick={handleFileUploadClick}>
            {PDF ? (
                displaySelectedFileMessage()
            ) : (
                <div className={"fileDropperContentsContainer"}>
                    <UploadFile className={styles.uploadIcon}></UploadFile>
                    <Typography variant={"h5"} color={"text.primary"}>
                        Upload your notes as a PDF document
                    </Typography>
                    <Typography variant={"caption"} color={"text.secondary"}>
                        max. 50MB
                    </Typography>
                </div>
            )}
        </FileDrop>
    )

    function handleIsDocumentEditChange() {
        if (isDocumentEdit) {
            setIsDocumentEdit(false)
            setIsMetadataEdit(false)
        } else {
            setIsDocumentEdit(true)
            setIsMetadataEdit(true)
        }
    }

    return (
        <>
            <Helmet>
                <title>Upload | NoteTaking.Club</title>
            </Helmet>
            <div className={formStyles.topLevelContainer}>
                <Tabs
                    className={styles.tabs}
                    value={tabNumber}
                    onChange={handleTabClick}
                    aria-label=""
                    orientation={"vertical"}
                    indicatorColor="secondary"
                >
                    <Tab className={styles.tab} label="Details" />
                    <Tab className={styles.tab} label="File Upload" />
                    <Tab className={styles.tab} label="Preview" />
                    <Tab className={styles.tab} label="Payment" />
                    <Tab className={styles.tab} label="Submit" />
                </Tabs>
                <div className={formStyles.formContainer}>
                    <div ref={detailsRef} className={`${formStyles.header} ${styles.header}`}>
                        Details
                    </div>

                    <div className={formStyles.subtitle}>
                        <span>Learn more about what institutions are available on NoteTaking.Club by clicking</span>
                        &nbsp;
                        <span
                            className={styles.linkText}
                            onClick={() =>
                                history.push({
                                    pathname: `/FAQ`,
                                    state: {
                                        openCategories: ["Institutions"],
                                    },
                                })
                            }
                        >
                            here.
                        </span>
                    </div>

                    <div className={`${formStyles.fieldContainer} ${styles.fieldContainer}`} onClick={() => setTabNumber(0)}>
                        <div className={formStyles.fieldRow}>
                            <CustomAutoComplete
                                isDisabled={isLoading}
                                options={institutionArray}
                                value={institution}
                                setter={setInstitution}
                                label={"Institution"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                        </div>
                        <div className={formStyles.fieldRow}>
                            <CustomAutoComplete
                                isDisabled={isLoading}
                                options={courseFieldArray}
                                value={courseField}
                                setter={setCourseField}
                                label={"Field of Study"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                                isCourseFields={true}
                            />

                            {/* <CustomFormControl isDisabled={isLoading} value={courseField} setter={setCourseField} label={"Field of Study"}
                                               alertState={alertState} incorrectField={incorrectField}/> */}
                            <CustomFormControl
                                isDisabled={isLoading}
                                value={prof}
                                setter={setProf}
                                label={"Prof"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                        </div>
                        <div className={formStyles.fieldRow}>
                            <CustomFormControl
                                isDisabled={isLoading}
                                value={courseCode}
                                setter={setCourseCode}
                                label={"Course Code"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                            <CustomFormControl
                                isDisabled={isLoading}
                                value={courseTitle}
                                setter={setCourseTitle}
                                label={"Course Title"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                        </div>
                        <div className={formStyles.fieldRow}>
                            <CustomAutoComplete
                                isDisabled={isLoading}
                                options={semesterOptions}
                                value={semester}
                                setter={setSemester}
                                label={"Semester"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                            <CustomFormControl
                                isDisabled={isLoading}
                                value={year}
                                setter={setYear}
                                label={"Year"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                        </div>
                        <div className={formStyles.fieldRow}>
                            <CustomMultiline
                                isDisabled={isLoading}
                                value={title}
                                setter={setTitle}
                                label={"Title"}
                                rows={1}
                                alertState={alertState}
                                incorrectField={incorrectField}
                                helperText={"max. 50 characters"}
                                className={formStyles.wideField}
                                charLimit={50}
                            />
                        </div>
                        <div className={formStyles.fieldRow}>
                            <CustomMultiline
                                isDisabled={isLoading}
                                value={description}
                                setter={setDescription}
                                label={"Description"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                                helperText={"max. 200 characters"}
                                className={formStyles.wideField}
                                charLimit={200}
                            />
                        </div>
                    </div>

                    <div ref={fileUploadRef} className={`${formStyles.header} ${styles.header}`}>
                        File Upload
                    </div>
                    {props.isEdit && (
                        <div
                            onClick={() => setTabNumber(1)}
                            className={`${formStyles.checkboxContainer} ${!isDocumentEdit && styles.fieldCheckbox}`}
                        >
                            <Checkbox onChange={handleIsDocumentEditChange} />
                            <div>
                                <span className={styles.checkboxText}>Do you want to upload a new document?</span>
                            </div>
                        </div>
                    )}
                    {(!props.isEdit || isDocumentEdit) && (
                        <div onClick={() => setTabNumber(1)} className={`${formStyles.fieldContainer} ${styles.fieldContainer}`}>
                            <FileDrop onDrop={handlePDFDrop} onTargetClick={handleFileUploadClick}>
                                {PDF ? (
                                    displaySelectedFileMessage()
                                ) : (
                                    <div className={"fileDropperContentsContainer"}>
                                        <UploadFile className={styles.uploadIcon}></UploadFile>
                                        <Typography variant={"h5"} color={"text.primary"}>
                                            Upload your notes as a PDF document
                                        </Typography>
                                        <Typography variant={"caption"} color={"text.secondary"}>
                                            max. 50MB
                                        </Typography>
                                    </div>
                                )}
                            </FileDrop>
                        </div>
                    )}
                    <div ref={previewRef} className={`${formStyles.header} ${styles.header}`}>
                        Preview & Thumbnail
                    </div>
                    <div className={`${formStyles.subtitle} ${styles.previewSubtitles}`}>
                        Choose a range of pages users will be able to preview before buying your notes as well as a the page in the
                        thumbnail.
                    </div>
                    {props.isEdit && (
                        <div
                            onClick={() => setTabNumber(2)}
                            className={`${formStyles.checkboxContainer} ${!isMetadataEdit && styles.fieldCheckbox}`}
                        >
                            <Checkbox checked={isMetadataEdit} onChange={() => setIsMetadataEdit(!isMetadataEdit)} />
                            <div>
                                <span className={styles.checkboxText}>Do you want to change the preview?</span>
                            </div>
                        </div>
                    )}
                    {(!props.isEdit || isMetadataEdit) && (
                        <div
                            onClick={() => setTabNumber(2)}
                            className={`${formStyles.fieldContainer} ${styles.fieldContainer} ${props.isEdit && styles.previewField}`}
                        >
                            <div className={formStyles.fieldRow}>
                                <CustomFormControl
                                    isDisabled={isLoading}
                                    value={firstPage}
                                    setter={setFirstPage}
                                    label={"First page of preview"}
                                    alertState={alertState}
                                    incorrectField={incorrectField}
                                    type={"number"}
                                />
                                <CustomFormControl
                                    isDisabled={isLoading}
                                    value={lastPage}
                                    setter={setLastPage}
                                    label={"Last page of preview"}
                                    alertState={alertState}
                                    incorrectField={incorrectField}
                                    type={"number"}
                                />
                            </div>
                            <div className={formStyles.fieldRow}>
                                <CustomFormControl
                                    isDisabled={isLoading}
                                    value={thumbnailPage}
                                    setter={setThumbnailPage}
                                    label={"Thumbnail page"}
                                    alertState={alertState}
                                    incorrectField={incorrectField}
                                    type={"number"}
                                />
                            </div>
                        </div>
                    )}

                    <div ref={paymentRef} className={`${formStyles.header} ${styles.header}`}>
                        Payment
                    </div>

                    <div className={formStyles.subtitle}>
                        {isOnboarded ? (
                            <span>You have completed stripe onboarding. You can view your payment information on the settings page.</span>
                        ) : (
                            <span>As of now, we only support payment through Stripe. To learn more about payment,</span>
                        )}
                        &nbsp;
                        {!isOnboarded && (
                            <span
                                className={styles.linkText}
                                onClick={() =>
                                    history.push({
                                        pathname: `/FAQ`,
                                        state: {
                                            openCategories: ["Selling"],
                                        },
                                    })
                                }
                            >
                                click here.
                            </span>
                        )}
                    </div>
                    <div onClick={() => setTabNumber(3)} className={`${formStyles.fieldContainer} ${styles.fieldContainer}`}>
                        <div className={formStyles.fieldRow}>
                            <CustomAutoComplete
                                isDisabled={isLoading}
                                options={currencyOptions}
                                value={currency}
                                setter={setCurrency}
                                label={"Currency"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                                className={styles.currencyField}
                            />
                            <CustomFormControl
                                isDisabled={isLoading}
                                value={authorsCut}
                                setter={handleAuthorsCutChange}
                                label={"You receive"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                            <CustomFormControl
                                isDisabled={isLoading}
                                value={price}
                                setter={handleListedPriceChange}
                                label={"Buyers pay"}
                                alertState={alertState}
                                incorrectField={incorrectField}
                            />
                        </div>
                        {parseFloat(price) === 0 && (
                            <div className={styles.connectMessageText}>
                                You dont have to connect your stripe wallet if your notes are free.
                            </div>
                        )}
                        <Button
                            onClick={handleCreateOnboarding}
                            style={{ display: isOnboarded ? "none" : "inline-flex" }}
                            disabled={isSaveDisabled()}
                            size={"large"}
                            variant={"contained"}
                            color={"secondary"}
                            className={styles.stripeButton}
                        >
                            Connect to Stripe
                        </Button>
                    </div>

                    <div ref={submitRef} className={`${formStyles.header} ${styles.header}`}>
                        Submit
                    </div>
                    {!props.isEdit && (
                        <div onClick={() => setTabNumber(4)} className={formStyles.checkboxContainer}>
                            <Checkbox onChange={() => setTosChecked(!tosChecked)} />
                            <div>
                                <span className={styles.checkboxText}>I have read and agreed to the</span>{" "}
                                <Link href={"/tos"}>Terms of Service.</Link>
                            </div>
                        </div>
                    )}

                    {/*<div className={formStyles.checkboxContainer}>*/}
                    {/*    <Checkbox checked={anonymityChecked} onChange={() => setAnonymityChecked(!anonymityChecked)}/>*/}
                    {/*    <div className={styles.checkboxText}>*/}
                    {/*        Dont show my display name to other users.*/}
                    {/*        <div className={formStyles.checkboxHelperText}>*/}
                    {/*            To read more about uploading anonymously, visit our <Link href={"/tos"}>FAQ.</Link>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <Button
                        disabled={isSaveDisabled()}
                        variant={"outlined"}
                        className={`${formStyles.submitButton} ${styles.submitButton}`}
                        onClick={handleSubmit}
                    >
                        {isLoading ? <CircularProgress size={"24px"} className={formStyles.loadingWheel} /> : "Submit"}
                    </Button>
                    <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration} onClose={() => setIsSnackBarOpen(false)}>
                        <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity} sx={{ width: "100%" }}>
                            {alertState.message}
                        </MuiAlert>
                    </Snackbar>
                    <input onChange={(event) => handlePDFDrop(event)} ref={inputFile} type="file" style={{ display: "none" }} />
                </div>
            </div>
        </>
    )
}
