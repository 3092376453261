import React from 'react'
import {Helmet} from 'react-helmet'
import homeStyles from "../styles/homepage.module.css";
import styles from "../styles/faq.module.css";
import faqCategories from "../data/faqCategories.json";
import FAQCategory from "../components/FAQCategory";
import {useHistory} from "react-router";
import {History} from "history";


const FAQ: React.FC = () => {
    const history = useHistory<History>();

    const openCategories: string[] = history.location?.state ? history.location?.state[`openCategories`] : [];

    return (
        <>
            <Helmet>
                <title>FAQ | NoteTaking.Club</title>
            </Helmet>
            <div className={homeStyles.topLevelContainer}>
                <div className={styles.faqContainer}>
                    <div className={styles.header}>FAQ</div>
                    <div className={styles.categoryContainer}>
                        {faqCategories.map((category) => (
                            <FAQCategory category={category} open={openCategories.includes(category.title)}/>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQ