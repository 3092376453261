import React from 'react'
import {useHistory} from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logoWithText from '../assets/logo with text dark.svg'
import IconButton from '@mui/material/IconButton'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import styles from '../styles/footer.module.css'

function Copyright() {
    return (
      <Typography variant="body2" color='#818EB1' align="center" sx = {{fontSize : '12px', paddingTop: '10px'}}>
        <p color="inherit">
        © {new Date().getFullYear()} NoteTaking.Club Inc.
        </p>{' '}
        
      </Typography>
    );
  }

const Footer: React.FC = () => {
    const history = useHistory()
    return (
        <Box sx={{ bgcolor: "#EEF3FC", p: 6, marginTop: "auto" }} component="footer">
            <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p"
                sx={{
                    fontFamily: "Arial, sans-serif",
                }}
            >
                <img src={logoWithText} width="247.5px" height="34.5px" />
            </Typography>
            <Grid
                container
                className={styles.footerLinks}
                sx={{ width: "300px", pt: "15px", margin: "auto", fontFamily: "Inter, sans-serif", textAlign: "center", fontSize: 18 }}
            >
                <Grid item xs={4} sx={{ m: "auto" }}>
                    <p
                        className={styles.footerLink}
                        onClick={() => {
                            history.push("/about")
                        }}
                    >
                        {" "}
                        About{" "}
                    </p>
                </Grid>
                <Grid item xs={4} sx={{ m: "auto" }}>
                    <p
                        className={styles.footerLink}
                        onClick={() => {
                            history.push("/faq")
                        }}
                    >
                        {" "}
                        FAQ{" "}
                    </p>
                </Grid>
                <Grid item xs={4} sx={{ m: "auto" }}>
                    <p
                        className={styles.footerLink}
                        onClick={() => {
                            history.push("/contact")
                        }}
                    >
                        {" "}
                        Contact{" "}
                    </p>
                </Grid>
            </Grid>

            <Grid
                container
                sx={{ width: "200px", margin: "auto", pt: "10px", fontFamily: "Inter, sans-serif", textAlign: "center", fontSize: 14 }}
            >
                <Grid item xs={4} sx={{ m: "auto" }}>
                    <IconButton
                        className={styles.socials}
                        sx={{ color: "#404758" }}
                        href="https://www.facebook.com/notetakingclubofficial"
                        target="_blank"
                    >
                        <FacebookIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={4} sx={{ m: "auto" }}>
                    <IconButton
                        className={styles.socials}
                        sx={{ color: "#404758" }}
                        href="https://www.instagram.com/notetakingclub"
                        target="_blank"
                    >
                        <InstagramIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={4} sx={{ m: "auto" }}>
                    <IconButton
                        className={styles.socials}
                        sx={{ color: "#404758" }}
                        href="https://twitter.com/notetaking_club"
                        target="_blank"
                    >
                        <TwitterIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid
                container
                sx={{ width: "270px", margin: "auto", pt: "10px", fontFamily: "Inter, sans-serif", textAlign: "center", fontSize: 15 }}
            >
                <Grid item xs={6} sx={{ m: "auto" }}>
                    <a
                        className={styles.legal}
                        href={
                            "https://docs.google.com/document/u/2/d/e/2PACX-1vTtSi3F2FIAnRkIVXysGcibSkA6xCI6UQnLvz1ic68frLjIJJnWqdQ6SFjeYepOoNZoBTdLzUW5pvLg/pub"
                        }
                    >
                        <p className={styles.legal}> Terms of Service </p>
                    </a>
                </Grid>
                <Grid item xs={6} sx={{ m: "auto" }}>
                    <a
                        className={styles.legal}
                        href={
                            "https://docs.google.com/document/d/e/2PACX-1vRQ_SplZKMZvxbo1vJhBMJGlnJPHD8ICzRlv8LirO8vZOewlINxur2l2x2Yp3r3JZNll5IYbCvAm6YK/pub"
                        }
                    >
                        <p className={styles.legal}> Privacy </p>
                    </a>
                </Grid>
            </Grid>

            <Copyright />
        </Box>
    )}

    export default Footer
