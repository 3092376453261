import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
const UnPrivateRoute = ({ component: RouteComponent, isSignup, ...rest }) => {
	const { user } = useContext(AuthContext);
	return (
		<Route
			{...rest}
			render={(routeProps) => {
					if (user !== null) {
							console.log("You are already logged in!")
							return (<Redirect
								to={{
									pathname: "/dashboard",
									state: { prevPath: rest.path },
								}}
							/>)
					
			            } else {
                            return <RouteComponent isSignup = {isSignup} {...routeProps} />;
                        }
                     
                    
                } 
            }
		/>
	);
};
export default UnPrivateRoute;
