import { useTheme } from "@mui/material/styles"
import React from "react"
import { Helmet } from "react-helmet"
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom"
import Footer from "./components/Footer"
import { Navbar } from "./components/Navbar"
import PrivateRoute from "./components/PrivateRoute"
import { ScrollToTop } from "./components/ScrollToTop"
import UnPrivateRoute from "./components/UnPrivateRoute"
import VerifyEmail from "./components/VerifyEmail"
import { About } from "./pages/About"
import Auth from "./pages/Auth"
import AutoClose from "./pages/AutoClose"
import { Contact } from "./pages/Contact"
import Dashboard from "./pages/Dashboard"
import Edit from "./pages/Edit"
import FAQ from "./pages/FAQ"
import Home from "./pages/Home"
import Institutions from "./pages/Institutions"
import Notes from "./pages/Notes"
import NotFound from "./pages/NotFound"
import { Recover } from "./pages/Recover"
import ReportNotes from "./pages/ReportNotes"
import RequestCourse from "./pages/RequestCourse"
import RequestInstitution from "./pages/RequestInstitution"
import SearchResults from "./pages/SearchResults"
import { Settings } from "./pages/Settings"
import { Upload } from "./pages/Upload"
import styles from "./styles/app.module.css"

function App() {
    const theme = useTheme()

    return (
      <>
        <Helmet>
          <title>NoteTaking.Club</title>
        </Helmet>
        <Router>
          <div
            className={styles.surrogateBody}
            style={{
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Navbar />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/giveaway">
                <Redirect
                  to={{
                    pathname: "/",
                    state: { referrer: "giveaway" },
                  }}
                />
              </Route>
              <Route path="/welcome/pamphlet">
                <Redirect
                  to={{
                    pathname: "/",
                    state: { referrer: "welcome/pamphlet" },
                  }}
                />
              </Route>
              <Route path="/welcome/platform">
                <Redirect
                  to={{
                    pathname: "/",
                    state: { referrer: "welcome/platform" },
                  }}
                />
              </Route>
              <UnPrivateRoute path="/signup" isSignup={true} exact component={Auth} />
              <UnPrivateRoute path="/login" isSignup={false} exact component={Auth} />
              <PrivateRoute path="/upload" isEdit={false} checkEmailVerified={true} exact component={Upload} />
              <PrivateRoute path="/dashboard" checkEmailVerified={false} exact component={Dashboard} />
              <PrivateRoute path="/dashboard/:activeTab" checkEmailVerified={false} exact component={Dashboard} />
              <Route path="/recover" exact component={Recover} />
              <Route path="/Notes/:id" exact component={Notes} />
              <Route path="/search-results" exact component={SearchResults} />
              <PrivateRoute path="/notes/:id/edit" checkEmailVerified={false} isEdit={true} exact component={Edit} />
              <PrivateRoute path="/verify" checkEmailVerified={false} exact component={VerifyEmail} />
              <PrivateRoute path="/request-institution" checkEmailVerified={true} exact component={RequestInstitution} />
              <PrivateRoute path="/request-course" checkEmailVerified={true} exact component={RequestCourse} />
              <PrivateRoute path="/report-notes/:id" checkEmailVerified={true} exact component={ReportNotes} />
              <PrivateRoute path="/settings" checkEmailVerified={false} exact component={Settings} />
              <Route path="/faq" exact component={FAQ} />
              <Route path="/institutions" exact component={Institutions} />
              <Route path="/contact" exact component={Contact} />
              <Route path="/auto-close" exact component={AutoClose} />
              <Route path="/about" exact component={About} />
              <Route path="*" component={NotFound} />
            </Switch>
            <div className={styles.divider}></div>
            <Footer />
          </div>
          <ScrollToTop />
        </Router>
      </>
    )
}

export default App
