import React, {useEffect} from "react";
import {IAlert} from "../models/IAlert";
import TextField from "@mui/material/TextField";
import styles from "../styles/form.module.css";
import Autocomplete from "@mui/material/Autocomplete";

export const CustomAutoComplete: React.FC<{isDisabled: boolean, options: any, value: string, setter: any, label: string, alertState: IAlert, incorrectField: string | Array<string>, className?: string, helperText?: string, isCourseFields?: boolean}> = (props) => {
    const isErrored = () =>{
        return (props.incorrectField.includes(props.label.toLocaleLowerCase()))
    }

    const helperText = () =>{
        if(isErrored()){
            return props.alertState.message
        }else{
            return props.helperText ? props.helperText : null
        }
    }

    useEffect(() => {
        props.setter(props.options[0] && props.value === "" ? props.options[0].value : props.value)
    },[] )

    return (    
        <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disabled={props.isDisabled}
            disablePortal
            options={props.options}
            noOptionsText = {props.isCourseFields ? `Field not found. Please search and select "Other"`: "No results"}
            value={props.value ? {label: props.value, value: props.value} : null}
            className={`${styles.field} ${props.className}`}
            onChange={(e, value) => {
                props.setter(value?.label);
            }}
            
            renderInput={(params) => <TextField
                {...params}
                error={isErrored()}
                helperText={helperText()}
                label={props.label}
                variant="standard"
            />}
        />
    )
}
