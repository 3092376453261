import axios from "axios";
import {FieldError} from "./models/FieldError";
import {KeyValuePairs} from "./models/KeyValuePairs";

const functionsDomain = process.env.REACT_APP_FUNCTIONS_DOMAIN

function handleErrors(error: any){
    if(error.response){
        if(error.response.data.fieldName){
            throw new FieldError(error.response.data.fieldName, error.response.data.error)
        }else{
            throw new Error(error.response.data.error)
        }
    }else{
        console.dir(error.message)
        if(error.fieldName){
            throw new FieldError(error.fieldName, error.message)
        }else{
            throw error
        }
        throw error
    }
}

export const updateNotes = async (notesId: string, keyValuePairs: Array<{key: string, value: string}>, token: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/notes/update',{
            "notesId": notesId,
            "keyValuePairs": keyValuePairs
        }, config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const updateUser = async (keyValuePairs: Array<{key: string, value: string}>, token: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/users/update',{
            "keyValuePairs": keyValuePairs
        }, config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const createNotes = async (title: string, keyValuePairs: KeyValuePairs, token: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/notes/create',{"keyValuePairs": keyValuePairs}, config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}


export const deleteNotes = async (notesId: string, token: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                notesId: notesId
            }
        }
        const res = await axios.delete(functionsDomain + '/notes/delete', config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const getNotes = async (notesId: string, token: string) =>{
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                notesId: notesId
            }
        }
        const res = await axios.get(functionsDomain + '/notes/get', config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const getUser = async (token: string) =>{
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        }
        const res = await axios.get(functionsDomain + '/users/get', config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const getUserDisplayName = async (userId: string) =>{
    try{
        let config = {
            params: {
                userId: userId
            }
        }
        const res = await axios.get(functionsDomain + '/users/get_display_name', config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const createAccount = async (email:string, displayName:string, password:string) => {
    try{
        const res = await axios.post(functionsDomain + '/users/create', {
            email: email,
            displayName: displayName,
            password: password
        })
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const validatePurchase = async (token: string, notesId: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/users/validate_purchase', {notesId: notesId} , config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const authorizeViewNotes = async (token: string, notesId: string) => {
    try {
        let config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const res = await axios.post(functionsDomain + "/users/authorize_view_notes", { notesId: notesId }, config)
        return res.data
    } catch (error) {
        handleErrors(error)
    }
}

export const getRatingByUser = async (token: string, notesId: string) => {
    try {
        let config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: { notesId: notesId },
        }
        const res = await axios.get(functionsDomain + "/ratings/get", config)
        return res.data
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.error)
        } else {
            console.dir(error.message)
            throw error
        }
    }
}

export const rateNotes = async (token: string, notesId: string, givenRating: number) => {
    try {
        let config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const res = await axios.post(functionsDomain + "/notes/rate", { notesId: notesId, givenRating: givenRating }, config)
        return res.data
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.error)
        } else {
            console.dir(error.message)
            throw error
        }
    }
}


export const purchaseNotes = async (token: string, notesId: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/notes/purchase', {notesId: notesId} , config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const getPaginatedNotes = async (lastDoc: string | undefined, currentTab:string, token:string) => {
    var functionRoute = ""

    switch (currentTab) {
        case "viewed-notes":
            functionRoute = "/notes/get_next_viewed"
            break;
        case "purchased-notes":
            functionRoute = "/notes/get_next_purchased"
            break;
        case "uploaded-notes":
            functionRoute = "/notes/get_next_uploaded"
            break;
        default:
            throw new Error(`Couldn't retrieve notes for ${currentTab}.`)
    }

    try {
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {lastDoc: lastDoc}
        }
        const res = await axios.get(functionsDomain + functionRoute, config)
        return res.data
    } catch (error) {
        handleErrors(error)
    }
}

export const requestCourse = async (courseCode: string, courseTitle: string, school: string, token: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/courses/request',{
            courseCode: courseCode,
            courseTitle: courseTitle,
            school: school
        }, config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}
export const requestInstitution = async (institution: string, email: string, token: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/institutions/request',{
            institution: institution,
            email: email
        }, config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const reportNotes = async (notesId: string, reason: string, message: string, token: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/notes/report',{
            notesId: notesId,
            reason: reason,
            message: message
        }, config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const addContactMessageAuth = async (name: string, email: string, message: string, token?: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/contact-messages-auth',{
            name: name,
            email: email,
            message: message
        }, config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const addContactMessage = async (name: string, email: string, message: string) => {
    try{
        const res = await axios.post(functionsDomain + '/contact-messages',{
            name: name,
            email: email,
            message: message
        })
        return res.data
    }catch(error){
        handleErrors(error)
    }
}


export const createCheckoutUrl = async (notesId: string, token: string, thumbnailUrl: string) => {
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/create-checkout-session',{
            notesId: notesId,
            thumbnailUrl: thumbnailUrl
        }, config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const getCourseIds = async (courseCode: string) =>{
    try{
        let config = {
            params: {
                courseCode: courseCode
            }
        }
        const res = await axios.get(functionsDomain + '/courses/search', config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const getInstitutionIds = async (queryString: string) =>{
    try{
        let config = {
            params: {
                queryString: queryString
            }
        }
        const res = await axios.get(functionsDomain + '/institutions/search', config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}

export const getCourseFieldIds = async (queryString: string) =>{
    try{
        let config = {
            params: {
                queryString: queryString
            }
        }
        const res = await axios.get(functionsDomain + '/course_fields/search', config)
        return res.data
    }catch(error){
        handleErrors(error)
    }
}


export const createOnboardingUrl = async(token: string, returnUrl: string, refreshUrl: string) =>{
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/create-stripe-onboarding', {returnUrl: returnUrl, refreshUrl: refreshUrl}, config)
        return res.data
        
    } catch (error){
        handleErrors(error)
    }
}

export const getOnboardingStatus = async(token: string) =>{
    try{
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.get(functionsDomain + '/users/get_onboarding_status', config)
        return res.data
        
    } catch (error){
        handleErrors(error)
    }
}

export const createStripeLoginLink = async(token: string) =>{
    try{

        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const res = await axios.post(functionsDomain + '/create-stripe-login-link', {}, config)
        return res.data
        
    } catch (error){
        handleErrors(error)
    }
}