import React, {useState} from 'react';
import styles from "../styles/form.module.css";
import {CustomFormControl} from "../components/CustomFormControl";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {IAlert} from "../models/IAlert";
import Button from "@mui/material/Button"
import {Helmet} from 'react-helmet'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';


export const Recover: React.FC = () => {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
    const [incorrectField, setIncorrectField] = useState("")
    const [alertState, setAlertState] = useState<IAlert>({
        message: "",
        severity: "success",
        duration: 3000
    })

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            await sendPasswordResetEmail(getAuth() ,email)
            setIsLoading(false)
            setAlertState({
                message: "Successfully sent recovery instructions",
                severity: "success",
                duration: 3000
            })
            setIsSnackBarOpen(true)
            setIncorrectField("")
        } catch (error) {
            setIncorrectField(error.fieldName || "")
            setIsLoading(false)
            setAlertState({
                message: error.message,
                severity: "error",
                duration: 6000
            })
            setIsSnackBarOpen(true)
            console.log(error)
        }
    }



    return (
        <>
            <Helmet>
                <title>Recover Password | NoteTaking.Club</title>
            </Helmet>
            <div className={styles.topLevelContainer}>
                <div className={styles.authContainer}>
                    <div className={`${styles.header} ${styles.recoverHeader}`}>
                        Recover Password
                    </div>
                    <div className={styles.authFieldContainer}>
                        <div className={styles.fieldRow}>
                            <CustomFormControl isDisabled={isLoading} value={email} setter={setEmail} label={"Email"}
                                            alertState={alertState} incorrectField={incorrectField} className={styles.wideField}/>
                        </div>
                    </div>
                    <Button disabled={isLoading} variant={"outlined"} className={styles.submitButton}
                            onClick={handleSubmit}>
                        {
                            isLoading ?
                                <CircularProgress size={"24px"} className={styles.loadingWheel}/>
                                :
                                "Submit"
                        }
                    </Button>
                    <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration}
                            onClose={() => setIsSnackBarOpen(false)}>
                        <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity}
                                sx={{width: '100%'}}>
                            {alertState.message}
                        </MuiAlert>
                    </Snackbar>
                </div>
            </div>
        </>
    )
}