import React, {useEffect, useState} from "react";
import { User, onAuthStateChanged, getAuth } from "firebase/auth"

type ContextProps = {
    user: User | null
}

export const AuthContext = React.createContext<Partial<ContextProps>>({});

export const AuthProvider = ({children}: any) => {
    const [user, setUser] = useState(null as User | null)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        onAuthStateChanged(getAuth(), (user: any) => {
            setUser(user);
            setLoading(false);
        });
    }, []);

    return (
        <AuthContext.Provider
            value={{user}}>
            {!loading && children}
        </AuthContext.Provider>
    );
}