import React, {useState} from "react";

type SearchProps = {
    lastQuery: string,
    setLastQuery: any,
    searchObject: any,
    setSearchObject: any,
    loading: boolean,
    setLoading: any
};

export const SearchContext = React.createContext<Partial<SearchProps>>({});

export const SearchProvider = ({children}: any) => {
    const [searchObject, setSearchObject] = useState<any>(undefined);
    const [lastQuery, setLastQuery] = useState("");
    const [loading, setLoading] = useState(false);

    return (
        <SearchContext.Provider
            value={{lastQuery, setLastQuery, searchObject, setSearchObject, loading, setLoading}}>
            {children}
        </SearchContext.Provider>
    );
}