import React from 'react'
import {Upload} from "./Upload";


const Edit: React.FC = () => {
    return(
        <Upload isEdit={true}></Upload>
    )
}

export default Edit