import React, {useContext, useState, useEffect} from 'react'
import Button from "@mui/material/Button"
import {AuthContext} from "../contexts/AuthContext";
import styles from "../styles/form.module.css";
import {CustomFormControl} from "../components/CustomFormControl";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {IAlert} from "../models/IAlert";
import {Helmet} from 'react-helmet'
import Typography from '@mui/material/Typography'
import { requestInstitution } from '../apiRequests';

const RequestInstitution: React.FC = () => {
    const { user } = useContext(AuthContext);
    const [institution, setInstitution] = useState("")
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
    const [hideSubmit, setHideSubmit] = useState(false)
    const [incorrectField, setIncorrectField] = useState("")
    const [alertState, setAlertState] = useState<IAlert>({
        message: "",
        severity: "success",
        duration: 3000
    })

    useEffect(() => {
        if (user) {
          if (user.email) setEmail(user.email);
        }
      }, []);

    const handleSubmit = async () => {
        try {
            if (!user) throw new Error("You must be logged in to request an institution")
            if (!email) throw new Error("Please provide us with an email so we can update you if we add your institution")
            setIsLoading(true)
            const token = await user.getIdToken();
            console.log
            await requestInstitution(institution, email, token!)
            setIsLoading(false)
            setHideSubmit(true)
            setAlertState({
                message: "Request submitted successfully",
                severity: "success",
                duration: 3000
            })
            setIsSnackBarOpen(true)
            setIncorrectField("")
        } catch (error) {
            setIncorrectField(error.fieldName || "")
            setIsLoading(false)
            setAlertState({
                message: error.message,
                severity: "error",
                duration: 6000
            })
            setIsSnackBarOpen(true)
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Request An Institution | NoteTaking.Club</title>
            </Helmet>

         <div className={styles.topLevelContainer}>
            <div className={styles.formContainer}>
            {!hideSubmit ? (
                <>
                    <div className={styles.header}>
                        Request Institution
                    </div>
                    <div className={styles.fieldContainer}>
                        <div className={styles.fieldRow}>
                            <CustomFormControl isDisabled={isLoading} value={institution} setter={setInstitution} label={"Institution"}
                                            alertState={alertState} incorrectField={incorrectField}/>
                            <CustomFormControl isDisabled={isLoading} value={email} setter={setEmail} label={"Email"} alertState={alertState} incorrectField={incorrectField} isMultiLine={false} className={styles.wideField} type = {'email'}/>
                        </div>
                        
                    </div>
                    <Button disabled={isLoading} variant={"outlined"} className={styles.submitButton}
                            onClick={handleSubmit}>
                        {
                            isLoading ?
                                <CircularProgress size={"24px"} className={styles.loadingWheel}/>
                                :
                                "Submit"
                        }
                    </Button>
                </>
            ): (
                <>
                  <Typography align = 'center' variant = "h6" style={{ fontWeight: '400', marginTop: "20px", margin: 'auto' }}>
                    Thank you for requesting a new institution!
                  </Typography>
                  <Typography align = 'center' variant = "h6" style={{fontWeight: '400', margin: 'auto' }}>
                    {`We appreciate your feedback and will let you know if ${institution} is added.`}
                  </Typography>
                </>
            )}
            
                    <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration}
                            onClose={() => setIsSnackBarOpen(false)}>
                        <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity}
                                sx={{width: '100%'}}>
                            {alertState.message}
                        </MuiAlert>
                    </Snackbar>
                </div>
            </div>
        </>
    )
}

export default RequestInstitution

