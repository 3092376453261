import TextField from "@mui/material/TextField";
import styles from "../styles/form.module.css";
import React from "react";
import {IAlert} from "../models/IAlert";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton"
import {Visibility, VisibilityOff} from "@material-ui/icons";
import FormControl, {FormControlClasses} from "@mui/material/FormControl";
import Input from "@mui/material/Input"
import InputLabel from "@mui/material/InputLabel"
import FormHelperText from "@mui/material/FormHelperText";

export const CustomFormControl: React.FC<{
    isDisabled: boolean, value: string, setter: any, label: string, alertState:
        IAlert, incorrectField: string | Array<string>, className?: string, helperText?: string, type?: string, startAdornment?: any, isMultiLine?: boolean
}> = (props) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const isErrored = () => {
        return props.incorrectField.includes(props.label.toLocaleLowerCase())
    }

    const helperText = () => {
        if (isErrored()) {
            return props.alertState.message
        } else {
            return props.helperText ? props.helperText : null
        }
    }

    const endAdornment = () => {
        if(props.type === "password") {
            return (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                    >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                </InputAdornment>
            )
        }else{
            return undefined
        }
    }

    const type = () => {
        if (props.type === "password") {
            if (showPassword) {
                return "text"
            } else {
                return "password"
            }
        } else if (props.type) {
            return props.type
        } else {
            return "text"
        }
    }

    return (
        <>
        {!props.isMultiLine? (
            <FormControl variant='standard'
                        className={`${styles.field} ${props.className}`}>
            <InputLabel error={isErrored()}>{props.label}</InputLabel>
            <Input
                startAdornment={props.startAdornment}
                disabled={props.isDisabled}
                error={isErrored()}
                value={props.value}
                type={type()}
                endAdornment={endAdornment()}
                onChange={(e) => {
                    props.setter(e.target.value);
       }}
   />
   <FormHelperText error={isErrored()}>{helperText()}</FormHelperText>
</FormControl>
        ): (
            <TextField
                            id="standard-multiline-static"
                            label="Message"
                            multiline
                            disabled={props.isDisabled}
                            error={isErrored()}
                            value={props.value}
                            type={type()}
                            rows={4}
                            placeholder = "Message"
                            variant="standard"
                            className = {styles.multiline}
                            InputLabelProps={{
                                shrink: true
                            }}
                              onChange={(e) => {
                                props.setter(e.target.value);
                   }}
                        />
        )}
        </>
    )
}

