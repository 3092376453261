import React, {useState} from 'react';
import {useHistory} from "react-router";
import styles from "../styles/form.module.css";
import {IAlert} from "../models/IAlert";
import {CustomFormControl} from "./CustomFormControl";
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {Helmet} from 'react-helmet'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';


export const LoginForm: React.FC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
    const [incorrectField, setIncorrectField] = useState("")
    const [alertState, setAlertState] = useState<IAlert>({
        message: "",
        severity: "success",
        duration: 3000
    })

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setIncorrectField("")
            setIsLoading(true)
            await signInWithEmailAndPassword(getAuth(), email, password);
            history.push({pathname: `/Dashboard/viewed-notes`, state: {
                    alert: {
                        message: "Successfully signed up!",
                        severity: "success",
                        duration: 3000
                    },
                    isSnackBarOpen: true
                }});

        } catch (error) {
            setIncorrectField(error.fieldName || "")
            setIsLoading(false)
            setAlertState({
                message: error.message,
                severity: "error",
                duration: 6000
            })
            setIsSnackBarOpen(true)
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                    <title>Login | NoteTaking.Club</title>
            </Helmet>
            <form className={styles.authFieldContainer} onSubmit={handleSubmit}>
                <div className={styles.fieldRow}>
                    <CustomFormControl isDisabled={isLoading} value={email} setter={setEmail} label={"Email"}
                                    alertState={alertState} incorrectField={incorrectField} className={styles.wideField}/>
                </div>
                <div className={styles.fieldRow}>
                    <CustomFormControl isDisabled={isLoading} value={password} setter={setPassword} label={"Password"}
                                    alertState={alertState} incorrectField={incorrectField} className={styles.wideField} type={"password"}/>
                </div>
                <div className={styles.forgotPassword} onClick={() => history.push("/recover")}>Forgot your password?</div>
                <Button type="submit" disabled={isLoading} variant={"outlined"} className={styles.authButton}
                        onClick={handleSubmit}>
                    {
                        isLoading ?
                            <CircularProgress size={"24px"} className={styles.loadingWheel}/>
                            :
                            "Log In"
                    }
                </Button>
                <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration}
                        onClose={() => setIsSnackBarOpen(false)}>
                    <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity}
                            sx={{width: '100%'}}>
                        {alertState.message}
                    </MuiAlert>
                </Snackbar>
            </form>
        </>
    )
}