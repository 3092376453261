import {createTheme, Palette, PaletteOptions} from "@mui/material/styles";
import {Shadows} from "@mui/material/styles/shadows";



const palette: PaletteOptions = {
    mode: 'light',
    primary: {
        main: '#FAFAFA',
        contrastText: '#1C212E',
    },
    secondary: {
        main: '#7482FF',
    },
    text:{
        primary: '#333C53',
        secondary: '#818EB1',
        disabled: '#818EB1'
    },
    error: {
        main: '#FF6363',
    },
    warning: {
        main: '#FFD747',
    },
    info: {
        main: '#6CCAFF',
    },
    success: {
        main: '#61C366',
    },
    background: {
        default: '#F5F8FD',
    },
}

export const NTCTheme = createTheme({
    typography: {
        fontFamily: [
            "'Inter', sans-serif",
            "'DM Sans', sans-serif"
        ].join(','),
    },
    palette
    ,
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    "&.MuiButton-contained:hover": {
                        backgroundColor: 'rgb(116, 130, 255, 0.8)',
                    },
                    boxShadow: "none",
                    "&:hover": {
                        boxShadow: "none",
                    },
                    "&:active": {
                        boxShadow: "none",
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FAFAFA',
                    "& .MuiLinearProgress-bar": {
                        backgroundColor: 'rgb(116, 130, 255, 0.5)',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: "#818EB1",
                    "&.Mui-selected": {
                        color: "#333C53"
                    },
                },
            },
        },
        MuiCheckbox:{
            styleOverrides: {
                root: {
                    "&:active": {
                        color: "#7482FF"
                    },
                    "&.Mui-checked": {
                        color: "#7482FF"
                    },
                }
            }
        },
        MuiLink:{
            styleOverrides: {
                root: {
                    color: '#7482FF',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& label.Mui-focused": {
                        color: "#7482FF"
                    },
                    "& .MuiInput-underline:before": {
                        borderBottomColor: "#818EB1"
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "#7482FF"
                    }
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&:before": {
                        borderBottomColor: "#818EB1"
                    },
                    "&:after": {
                        borderBottomColor: "#7482FF"
                    }
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    "& label.Mui-focused": {
                        color: "#7482FF"
                    },
                    "& .MuiInput-underline:before": {
                        borderBottomColor: "#818EB1"
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "#7482FF"
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    "& .MuiButton-text": {
                        color: "#7482FF"
                    }
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#818EB1",
                },
            },
        },
    },
});