import React, {useState} from 'react';
import {createAccount} from "../apiRequests";
import {useHistory} from "react-router";
import styles from "../styles/form.module.css";
import {IAlert} from "../models/IAlert";
import {CustomFormControl} from "./CustomFormControl";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {FieldError} from "../models/FieldError";
import {Helmet} from 'react-helmet';
import { signInWithEmailAndPassword, getAuth, sendEmailVerification } from 'firebase/auth';


export const SignupForm: React.FC = () => {
    const [email, setEmail] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [password, setPassword] = useState('')
    const [passwordCheck, setPasswordCheck] = useState('')
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
    const [incorrectField, setIncorrectField] = useState("")
    const [alertState, setAlertState] = useState<IAlert>({
        message: "",
        severity: "success",
        duration: 3000
    })
    const [tosChecked, setTosChecked] = useState(false)

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setIncorrectField("")
            setIsLoading(true)
            if (password === passwordCheck) {
                await createAccount(email, displayName, password)
                const currentUser = await signInWithEmailAndPassword(
                    getAuth(),
                    email,
                    password
                )
                if (currentUser.user != null) {
                    await sendEmailVerification(currentUser.user)
                } else {
                    throw new Error("currentUser is null")
                }
                history.push({
                    pathname: `/verify`, state: {
                        alert: {
                            message: "Successfully signed up!",
                            severity: "success",
                            duration: 3000
                        },
                        isSnackBarOpen: true
                    }
                });
            } else {
                throw new FieldError("confirm password", "Password confirmation must match password.")
            }

        } catch (error) {
            setIncorrectField(error.fieldName || "")
            setIsLoading(false)
            setAlertState({
                message: error.message,
                severity: "error",
                duration: 6000
            })
            setIsSnackBarOpen(true)
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Sign Up | NoteTaking.Club</title>
            </Helmet>
            <form className={styles.authFieldContainer} onSubmit={handleSubmit}>
                <div className={styles.fieldRow}>
                    <CustomFormControl
                        isDisabled={isLoading}
                        value={displayName}
                        setter={setDisplayName}
                        label={"Display Name"}
                        alertState={alertState}
                        incorrectField={incorrectField}
                        className={styles.wideField}
                    />
                </div>
                <div className={styles.fieldRow}>
                    <CustomFormControl
                        isDisabled={isLoading}
                        value={email}
                        setter={setEmail}
                        label={"Email"}
                        alertState={alertState}
                        incorrectField={incorrectField}
                        className={styles.wideField}
                    />
                </div>
                <div className={styles.fieldRow}>
                    <CustomFormControl
                        isDisabled={isLoading}
                        value={password}
                        setter={setPassword}
                        label={"Password"}
                        alertState={alertState}
                        incorrectField={incorrectField}
                        className={styles.wideField}
                        type={"password"}
                    />
                </div>
                <div className={styles.fieldRow}>
                    <CustomFormControl
                        isDisabled={isLoading}
                        value={passwordCheck}
                        setter={setPasswordCheck}
                        label={"Confirm Password"}
                        alertState={alertState}
                        incorrectField={incorrectField}
                        className={styles.wideField}
                        type={"password"}
                    />
                </div>
                <div className={styles.checkboxContainer}>
                    <Checkbox onChange={() => setTosChecked(!tosChecked)} />
                    <div>
                        I have read and agreed to the{" "}
                        <Link
                            href={
                                "https://docs.google.com/document/u/2/d/e/2PACX-1vTtSi3F2FIAnRkIVXysGcibSkA6xCI6UQnLvz1ic68frLjIJJnWqdQ6SFjeYepOoNZoBTdLzUW5pvLg/pub"
                            }
                        >
                            Terms of Service
                        </Link>
                    </div>
                </div>
                <Button
                    type={"submit"}
                    disabled={isLoading || !tosChecked}
                    variant={"outlined"}
                    className={styles.authButton}
                    onClick={handleSubmit}
                >
                    {isLoading ? <CircularProgress size={"24px"} className={styles.loadingWheel} /> : "Sign Up"}
                </Button>
                <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration} onClose={() => setIsSnackBarOpen(false)}>
                    <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity} sx={{ width: "100%" }}>
                        {alertState.message}
                    </MuiAlert>
                </Snackbar>
            </form>
        </>
    )
}