import React from "react"
import { Helmet } from "react-helmet"
import homeStyles from "../styles/homepage.module.css"
import styles from "../styles/faq.module.css"
import { getInstitutionIds } from "../apiRequests"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import { useHistory } from "react-router-dom"

const Institutions: React.FC = () => {
    const [institutionIds, setInstitutionIds] = React.useState<string[]>([])
    const [searchResults, setSearchResults] = React.useState<string[]>([])
    const inputRef = React.createRef<HTMLInputElement>()
    const history = useHistory()

    async function fetchInstitutions() {
        try{
            const ids = (await getInstitutionIds("")).institutionIds;
            setInstitutionIds(ids)
            setSearchResults(ids)
        }catch(e){

        }
    }

    const requestSearch = (searchedVal: string) => {
        const filteredRows = institutionIds.filter((id) => {
            return id.toLowerCase().includes(searchedVal.toLowerCase())
        })
        setSearchResults(filteredRows)
    }

    React.useEffect(() => {
        fetchInstitutions()
    }, [])
    
    return (
        <>
            <Helmet>
                <title>Institutions | NoteTaking.Club</title>
            </Helmet>
            <div className={homeStyles.topLevelContainer}>
                <div className={styles.faqContainer}>
                    <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", height: "40px" }}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            className={styles.searchBar}
                            inputRef={inputRef}
                            onChange={(newValue) => requestSearch(newValue.target.value)}
                            placeholder="Search available institutions"
                        />
                    </Paper>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableBody>
                                {searchResults.map((searchResults) => (
                                    <TableRow key={searchResults}>
                                        <TableCell component="th" scope="row">
                                            {searchResults}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style ={{ fontFamily: "Inter, sans-serif", padding: "20px 4px", textAlign: "center", alignItems: "center", height: "40px" }}> Want us to consider adding your institution? Click <a style = {{cursor: "pointer", color: "#7482FF" }} onClick = {() => {history.push("/request-institution")}}> here</a>. </div>

                </div>
            </div>
        </>
    )
}

export default Institutions
