import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Link from "@mui/material/Link"
import { getAnalytics, logEvent } from "firebase/analytics"
import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { useHistory, useLocation } from "react-router"
import { notesIndex } from "../algoliaSetup"
import barGraph from "../assets/bar graph.svg"
import networkImage from "../assets/buyer seller network.svg"
import uaFlag from "../assets/ua flag.svg"
import useWindowDimensions from "../components/useWindowDimensions"
import { SearchContext } from "../contexts/SearchContext"
import styles from "../styles/homepage.module.css"

const Home: React.FC = () => {
  interface IReferrer {
    referrer: string | undefined
  }

  const { width, height } = useWindowDimensions()
  const history = useHistory()
  const { state } = useLocation<IReferrer>()
  const inputRef = React.createRef<HTMLInputElement>()
  const { searchObject, setSearchObject, setLastQuery } = useContext(SearchContext)
  const [isGiveawayDialogOpen, setIsGiveawayDialogOpen] = React.useState(state && state.referrer === "giveaway")
  const [isReferredByWelcome, setIsReferredByWelcome] = React.useState(state && state.referrer?.includes("welcome") ? state.referrer : null)
  const [isSearchFocused, setIsSearchFocused] = React.useState(false)
  const analytics = getAnalytics()

  const styledButton = (text: string, inverted: boolean, onClick: any) => {
    return (
      <>
        <Button
          onClick={onClick}
          size={width > 1300 ? "medium" : "small"}
          variant={inverted ? "text" : "contained"}
          sx={{
            textTransform: "none",
            background: inverted ? "transparent" : "#7482FF",
            color: inverted ? "#7482FF" : "white",
            fontSize: "19px",
          }}
        >
          {text}
        </Button>
      </>
    )
  }

  function sendReferredByAdEvent() {
    try {
      if (isReferredByWelcome !== null && isReferredByWelcome === "welcome/pamphlet") {
        logEvent(analytics, "User was referred by pamphlet.")
      }
      if (isReferredByWelcome !== null && isReferredByWelcome === "welcome/platform") {
        logEvent(analytics, "User was referred by platform.")
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function search(searchQuery: string) {
    try {
      setSearchObject(
        await notesIndex.search(searchQuery, {
          filters: "unlisted:false AND permanentlyUnlisted:false",
          hitsPerPage: 12,
        })
      )
      setLastQuery(searchQuery)
      if (history.location.pathname !== "/search-results") {
        await history.push("/search-results")
      }
    } catch (error) {
      console.log(error)
    }
  }

  function handleKeyDown(e, searchQuery: string) {
    if (e.keyCode === 13) {
      search(searchQuery)
    }
  }

  function handleGiveawayClick() {
    try {
      setIsGiveawayDialogOpen(false)
      if (analytics) {
        logEvent(analytics, "User just entered the giveaway.")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const giveawayDialog = () => {
    return (
      <Dialog
        open={isGiveawayDialogOpen}
        onClose={() => setIsGiveawayDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Thank you for participating in our giveaway!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            To complete your entry, create an account and upload your notes.
            <br />
            <br />
            By continuing to use our site, you confirm that you have read and agreed to the
            <Link
              href={
                "https://docs.google.com/document/d/e/2PACX-1vS0E885-Ge-BIjO6uIiTjdTHyUOjk1gKGTwDM7rp436vhwDocubge8iaSnpIVYX8RfJeGIqZX_RW8qq/pub"
              }
            >
              {" "}
              terms of the giveaway.
            </Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGiveawayClick}>OK</Button>
        </DialogActions>
      </Dialog>
    )
  }

  React.useEffect(() => {
    sendReferredByAdEvent()
  }, [])

  return (
    <>
      <Helmet>
        <title>NoteTaking.Club</title>
      </Helmet>
      {giveawayDialog()}
      <div className={styles.topLevelContainer}>
        <div className={styles.landingTileContainer}>
          <div className={styles.landingTileInnerContainer}>
            <div className={styles.title}>
              An <mark style={{ background: "rgba(255,255,255,0.25)", color: "white" }}> easier</mark> way to learn and earn.
            </div>
            <a href={"https://www.ucc.ca/"} className={styles.subtitle}>
              <img src={uaFlag} className={styles.uaFlag} draggable="false" /> Find out how you can help Ukraine!
            </a>

            <div className={`${styles.searchContainer} ${isSearchFocused && styles.active} ${styles.search}`}>
              <input
                ref={inputRef}
                onKeyDown={(event) => handleKeyDown(event, inputRef.current?.value ?? "")}
                className={styles.searchInput}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                placeholder="Search by courses, institutions, and more..."
              ></input>

              <div
                className={`${styles.autocompleteItems} ${isSearchFocused && styles.active}`}
                onMouseDown={() => {
                  search(inputRef.current?.value ?? "")
                }}
              >
                <div className={styles.autocompleteItem}>Press ↵ Enter to see live results.</div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.tileContainer} ${styles.sellersTile}`}>
          <div className={`${styles.tileInnerContainer} ${styles.introTileInnerContainer}`}>
            <div className={styles.introTileLeftContainer}>
              <div className={styles.sellersTileTitle}>What makes us different?</div>
              <div className={`${styles.introSubtitle} ${styles.introFirstPagaragraph}`}>
                NoteTaking.Club is a free market with no subscriptions and no hassles.
              </div>
              <div className={styles.introSubtitle}>
                Made by students and for students, we’ve dedicated ourselves to create a fair community to facilitate mutually beneficial
                exchanges for everyone. Buyers get high quality note packets and sellers receive fair compensation for their studious work.
              </div>
              <div className={styles.introSubtitle}>No hidden fees to join the club either. Everyone wins.</div>
            </div>
            <img src={networkImage} className={styles.networkImage} draggable="false" />
          </div>
        </div>

        <div className={`${styles.tileContainer} ${styles.sellersTile}`}>
          <div className={styles.tileInnerContainer}>
            <div className={styles.sellersTileLeftContainer}>
              <div className={styles.sellersTileTitle}>Submit your notes and get paid</div>
              <div className={styles.sellersTileSubtitle}>Choose exactly how much you want to get paid per purchase. It's that simple.</div>
              <div className={`${styles.sideButtonContainer} ${styles.buttonContainer}`}>
                {styledButton("Start selling", false, () => history.push("/upload"))}
                {styledButton("How does it work?", true, () =>
                  history.push({
                    pathname: `/FAQ`,
                    state: {
                      openCategories: ["Uploading", "Selling"],
                    },
                  })
                )}
              </div>
            </div>
            <img src={barGraph} className={styles.barGraph} draggable="false" />

            <div className={`${styles.bottomButtonContainer}`}>
              {styledButton("Start selling", false, () => history.push("/upload"))}
              {styledButton("How does it work?", true, () => history.push("/FAQ"))}
            </div>
          </div>
        </div>

        <div className={`${styles.tileContainer} ${styles.statsTile}`} style={{ display: "none" }}>
          <div className={styles.tileInnerContainer}>
            <div className={styles.statsContainer}>
              <div className={styles.counterContainer}>
                <div className={styles.counter}>100M</div>
                <div className={styles.counterLabel}>UNIQUE NOTES</div>
              </div>
              <div className={styles.counterContainer}>
                <div className={styles.counter}>100K</div>
                <div className={styles.counterLabel}>CONTRIBUTORS</div>
              </div>
            </div>

            <div className={styles.statsInfoContainer}>
              <div className={styles.statsInfo}>Join our rising community and find the perfect notes to help you study.</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
