import React, {useContext, useState} from 'react'
import {AuthContext} from "../contexts/AuthContext";
import {reportNotes} from "../apiRequests";
import {useParams} from "react-router-dom";
import styles from "../styles/form.module.css";
import {CustomFormControl} from "../components/CustomFormControl";
import {CustomAutoComplete} from "../components/CustomAutoComplete";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {IAlert} from "../models/IAlert";
import Button from "@mui/material/Button"
import {CustomMultiline} from "../components/CustomMultiline";
import {Helmet} from 'react-helmet'

const ReportNotes: React.FC = () => {
    const { user } = useContext(AuthContext);
    const [message, setMessage] = useState("")
    const [reason, setReason] = useState("")
    const {id} = useParams<ParamTypes>();
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
    const [incorrectField, setIncorrectField] = useState("")
    const [alertState, setAlertState] = useState<IAlert>({
        message: "",
        severity: "success",
        duration: 3000
    })

    const reasons = [
        { label: "Intellectual Property Theft", value: "Intellectual Property Theft" },
        {
            label: "Misleading presentation",
            value: "Misleading presentation",
        },
        { label: "Illegible or poorly formated", value: "Illegible or poorly formated" },
        { label: "Inappropriate or illegal", value: "Inappropriate or illegal" },
    ]

    interface ParamTypes {
        id: string;
    }

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const token = await user?.getIdToken();
            await reportNotes(id, reason, message, token!)
            setIsLoading(false)
            setAlertState({
                message: "Report submitted successfully",
                severity: "success",
                duration: 3000
            })
            setIsSubmitted(true)
            setIsSnackBarOpen(true)
            setIncorrectField("")
        } catch (error) {
            setIncorrectField(error.fieldName || "")
            setIsLoading(false)
            setAlertState({
                message: error.message,
                severity: "error",
                duration: 6000
            })
            setIsSnackBarOpen(true)
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Report Note | NoteTaking.Club</title>
            </Helmet>

            <div className={styles.topLevelContainer}>
                {isSubmitted ? (
                    <div className={styles.formContainer}>
                        <div className={styles.submitConfirmationHeader}>
                            Report submitted!
                        </div>
                    </div>
                ) : (
                    <div className={styles.formContainer}>
                        <div className={styles.header}>Report</div>
                        <div className={styles.fieldContainer}>
                            <div className={styles.fieldRow}>
                                <CustomAutoComplete
                                    isDisabled={isLoading}
                                    options={reasons}
                                    value={reason}
                                    setter={setReason}
                                    label={"Reason"}
                                    alertState={alertState}
                                    incorrectField={incorrectField}
                                />
                            </div>
                            <div className={styles.fieldRow}>
                                <CustomMultiline
                                    isDisabled={isLoading}
                                    value={message}
                                    setter={setMessage}
                                    label={"Message"}
                                    alertState={alertState}
                                    incorrectField={incorrectField}
                                    className={styles.wideField}
                                />
                            </div>
                        </div>
                        <Button disabled={isLoading} variant={"outlined"} className={styles.submitButton} onClick={handleSubmit}>
                            {isLoading ? <CircularProgress size={"24px"} className={styles.loadingWheel} /> : "Submit"}
                        </Button>
                        <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration} onClose={() => setIsSnackBarOpen(false)}>
                            <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity} sx={{ width: "100%" }}>
                                {alertState.message}
                            </MuiAlert>
                        </Snackbar>
                    </div>
                )}
            </div>
        </>
    )
}

export default ReportNotes
