
import React, { useEffect } from 'react';
import {Redirect} from 'react-router-dom'

const AutoClose: React.FC = props => {  

    useEffect(() => {
        // Update the document title using the browser API
        window.close()
        //If window was opened by stripe onboarding it should close. 
      });
    return (<></>)
}

export default AutoClose

