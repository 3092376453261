import React, {useContext, useEffect, useRef, useState} from 'react'
import Divider from "@mui/material/Divider"
import {AuthContext} from "../contexts/AuthContext";
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {useParams} from 'react-router-dom';
import {INotes, priceToString} from '../models/INotes'
import {
    authorizeViewNotes,
    createCheckoutUrl,
    createStripeLoginLink,
    deleteNotes,
    getNotes,
    getRatingByUser,
    getUserDisplayName,
    rateNotes,
    updateNotes,
    validatePurchase
} from '../apiRequests'
import ViewSDKClient from "../components/ViewSDKClient";
import {useHistory} from "react-router";
import {History} from "history";
import styles from '../styles/notes.module.css'
import useWindowDimensions from "../components/useWindowDimensions";
import {
    DeleteOutline,
    EditOutlined,
    FlagOutlined,
    GradeOutlined,
    MessageOutlined,
    VisibilityOffOutlined,
    VisibilityOutlined
} from "@material-ui/icons";
import Button from "@mui/material/Button"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {IAlert} from "../models/IAlert";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {Helmet} from 'react-helmet'
import TextField from '@mui/material/TextField';
import { FieldError } from '../models/FieldError';
import Skeleton from '@mui/material/Skeleton';
import { getStorage, ref, getDownloadURL } from "firebase/storage"


const Notes: React.FC = () => {
    const {user} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [notesObj, setNotesObj] = useState<INotes>()
    const [previewURL, setPreviewURL] = useState("unknown")
    const [ownership, setOwnership] = useState("unknown")
    const [previewLoadState, setPreviewLoadState] = useState("Loading notes...")
    const [isDeleteModalActive, setIsDeleteModalActive] = React.useState(false)
    const [isRateModalActive, setIsRateModalActive] = React.useState(false)
    const [isContactModalActive, setIsContactModalActive] = React.useState(false)
    const [numPages, setNumPages] = useState(null);
    const history = useHistory<History>();
    const storage = getStorage()
    const {id} = useParams<ParamTypes>();
    const {width, height} = useWindowDimensions()
    const [authorName, setAuthorName] = useState("unknown")
    const [isViewDocDisabled, setIsViewDocDisabled] = useState(false)
    const [isSnackBarOpen, setIsSnackBarOpen] = useState( history.location.state && history.location.state[`isSnackBarOpen`] ? history.location?.state[`isSnackBarOpen`] : false)
    const [alertState, setAlertState] = useState<IAlert>(history.location.state && history.location?.state[`alert`] ? history.location?.state[`alert`] : {
        message: "",
        severity: "success",
        duration: 6000})

    const PREVIEW_SUCCESS_MSG = "Loaded"
    const PREVIEW_NO_DATA_MSG = "Fetching data."
    const PREVIEW_LOADING_MSG = "Loading notes..."
    const PREVIEW_FAILED_MSG = "Failed to load notes."
    const ratingRef = useRef<HTMLInputElement>(null)


    let loopUrlFetch = true;

    interface ParamTypes {
        id: string;
    }

    const handleErrors = (error) => {
        setAlertState({
            message: error.message,
            severity: "error",
            duration: 6000
        })
        setIsLoading(false)
        setIsSnackBarOpen(true)
    }

    const handleOpenStripeDash = async () => {
        let stripeLoginWindow
        try { 
            const token = await user?.getIdToken()
            stripeLoginWindow = window.open('', '_blank')
            stripeLoginWindow.document.write('Loading stripe dashboard...');
            const url = await createStripeLoginLink(token!)
            if (stripeLoginWindow) stripeLoginWindow.location.href = url.stripeLoginLink.url       
        }
        catch(error) {
            handleErrors(error)
            if (stripeLoginWindow) stripeLoginWindow.close()
        }
    }


    async function processPurchase() {
        try {
            if(!user){
                return history.push("/login")
            }
            const token = await user?.getIdToken()

            const pathToThumbnail = ref(storage, `notes/${notesObj?.authorId}/${notesObj?.id}/thumbnail.jpeg`)
            let thumbnailUrl = ""

            try{
                thumbnailUrl = await getDownloadURL(pathToThumbnail)
            }catch(error){
                console.log(error)
            }

            const response = await createCheckoutUrl(id, token!, thumbnailUrl)
            window.location.href = response.sessionUrl
        } catch (error) {
            handleErrors(error)
            console.log(error)
        }

    }

    async function processRating() {
        try {
            if(!user){
                return history.push("/login")
            }

            const token = await user?.getIdToken()

            if (!ratingRef.current?.value) throw new FieldError("Rating", "Rating must be a number")
            
            const rating = parseFloat(ratingRef.current?.value)

            await rateNotes(token!, id, rating)

            const result: INotes = await getNotes(id, token || "")
            setNotesObj(result)

            setIsRateModalActive(false)

            setAlertState({
                message: "Thank you for rating!",
                severity: "success",
                duration: 3000,
            })
            setIsSnackBarOpen(true)
            
        } catch (error) {
            handleErrors(error)
            console.log(error)
        }
    }

    async function processDelete() {
        try {
            const token = await user?.getIdToken()

            await deleteNotes(notesObj?.id!, token!)
            setIsDeleteModalActive(false)
            history.push("/")
        } catch (error) {
            handleErrors(error)
            setIsDeleteModalActive(false)
            console.log(error)
        }

    }

    async function processOwnershipValidation(notesDoc: INotes | undefined, user: any, token: any) {
        try{
            if(token){
                const response = await validatePurchase(token!, id)
                await user?.getIdToken(true)

                if (notesDoc?.authorId === user?.uid) {
                    setOwnership("authored")
                } else if (response.message === "Purchase validated. Token updated successfully.") {
                    setOwnership("purchased")
                } else {
                    setOwnership("not purchased")
                    if(notesDoc?.price === 0) await authorizeViewNotes(token, id)
                }
            }else{
                setOwnership("not purchased")
            }

        }catch(error){
            handleErrors(error)
            console.log(error)
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function fetchPreviewDownloadUrl(storageRef: any) {
        while ((!previewUrlDownloaded() && loopUrlFetch)) {
            try {
                const previewURL = await getDownloadURL(storageRef)
                setPreviewURL(previewURL)
                await sleep(2000)
                break;
            } catch (error) {
                setAlertState({
                    message: "Processing preview...",
                    severity: "warning",
                    duration: 6000
                })
                setIsSnackBarOpen(true)
                setPreviewURL("")
                await sleep(2000)
            }
        }
    }

    const closeSnackbar = () => {
        // @ts-ignore
        if (history.location.state && history.location.state.isSnackBarOpen) {
            // @ts-ignore
            let state = { ...history.location.state };
            delete state["alert"];
            delete state["isSnackBarOpen"];
            history.replace({ ...history.location, state });
        }
        setIsSnackBarOpen(false)
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                var token = await user?.getIdToken();
                const result: INotes = await getNotes(id, token || "")
                setNotesObj(result)
                const authorDisplayName = (await getUserDisplayName(result.authorId)).displayName
                setAuthorName(authorDisplayName)

                const pathToPreview = ref(storage, `notes/${result.authorId}/${id}/preview.pdf`)

                fetchPreviewDownloadUrl(pathToPreview)

                await processOwnershipValidation(result, user, token)
                setIsLoading(false)
            } catch (error) {
                handleErrors(error)
                console.log(error)
            }
        }
        fetchData()

        /**
         This function gets called on unmount (kinda like a chain). Its purpose is to stop the component from fetching
         url after you change pages.
         */
        return function cleanup() {
            loopUrlFetch = false;

        }
    }, []);

    const viewSDKClient = new ViewSDKClient();

    const displayFullDocument = async () => {
        try {

            if(!user){
                return history.push("/login")
            }

            setIsViewDocDisabled(true)
            setTimeout(function() {
                setIsViewDocDisabled(false)
            }, 2000);

            const viewConfig = {
                embedMode: "LIGHT_BOX",
                showDownloadPDF: false,
                showPrintPDF: false
            }
            await viewSDKClient.ready()
            //await viewSDKClient.registerSaveApiHandler()

            /**
             * Not sure if you have to validate twice but it works so fuck it.
             */
            

            await processOwnershipValidation(notesObj, user, await user?.getIdToken())
            await user?.getIdToken(true);

            const pathToFull = ref(storage ,`notes/${notesObj?.authorId}/${id}/full_document.pdf`)
            const fullURL = await getDownloadURL(pathToFull)

            await viewSDKClient.previewFile("", viewConfig, fullURL, notesObj?.title);

        } catch (error) {
            handleErrors(error)
            console.log(error)
        }
    }

    const displayPreviewMobile = async () => {
        try {
            setIsViewDocDisabled(true)
            setTimeout(function() {
                setIsViewDocDisabled(false)
            }, 2000);

            const viewConfig = {
                embedMode: "LIGHT_BOX",
                showDownloadPDF: false,
                showPrintPDF: false
            }
            await viewSDKClient.ready()
            //await viewSDKClient.registerSaveApiHandler()

            await viewSDKClient.previewFile("", viewConfig, previewURL, notesObj?.title);

        } catch (error) {
            handleErrors(error)
            console.log(error)
        }
    }

    const rateDialog = () => {
        return (
            <Dialog
                open={isRateModalActive}
                onClose={() => setIsRateModalActive(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Rate (out of 5)</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Thank you for rating this note. User feedback is what makes this site work.
                    </DialogContentText>
                    <TextField inputRef={ratingRef} autoFocus margin="dense" id="name" label="Rating" type="number" variant="standard" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsRateModalActive(false)}>Cancel</Button>
                    <Button onClick={() => processRating()} autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const contactDialog = () => {
        return (
            <Dialog
                open={isContactModalActive}
                onClose={() => setIsContactModalActive(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>This feature is still in developpement.</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setIsContactModalActive(false)}>OK</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const deleteDialog = () => {
        if (notesObj?.purchases !== undefined && notesObj?.purchases <= 0) {
            return <Dialog
                open={isDeleteModalActive}
                onClose={() => setIsDeleteModalActive(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete your notes?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setIsDeleteModalActive(false)}>No</Button>
                    <Button onClick={processDelete} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        } else {
            return <Dialog
                open={isDeleteModalActive}
                onClose={() => setIsDeleteModalActive(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Your notes have already been purchased and cannot be deleted."}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You can either unlist your notes or contact us at notetakingclub@gmail.com
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteModalActive(false)}>OK</Button>
                </DialogActions>
            </Dialog>
        }
    }

    function onPreviewLoad(numberOfPages) {
        setPreviewLoadState(PREVIEW_SUCCESS_MSG)
        setNumPages(numberOfPages)
    }

    function calculatePageWidth(){
        return Math.min((width*0.72-331-35), 994)
    }

    function calculatePageHeight(){
        return calculatePageWidth()*1.4142
    }

    function displayPreviewPages() {
        return Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map(page => <div>
                <Page width={calculatePageWidth()} renderTextLayer={false} renderAnnotationLayer={false}
                      pageNumber={page}>
                </Page>
                <br/>
            </div>)
    }

    function previewUrlDownloaded() {
        return previewURL.includes("notes")
    }


    function createDateString(createdAt) {
        if (createdAt) {
            const milliSeconds = typeof createdAt === "number" ? createdAt : createdAt._seconds * 1000
            const date = new Date(milliSeconds)
            return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
        }
        return "No date"
    }

    async function updateUnlistedValue(value: boolean) {
        try {
            const keyValuePairs: Array<{ key: string, value: any }> = []
            keyValuePairs.push({key: "unlisted", value: value})
            const token = await user?.getIdToken()!
            await updateNotes(notesObj?.id!, keyValuePairs, token)
            const result: INotes = await getNotes(id, token || "")
            setNotesObj(result)

        } catch (error) {
            handleErrors(error)
            console.log(error)
        }
    }

    const institution = () => {
        return notesObj?.institution
    }

    const courseCode = () => {
        return notesObj?.courseCode
    }

    const authorDisplayName = () => {
        return notesObj?.anonymity ? "Anonymous" : authorName
    }

    interface labelValuePair {
        label: string,
        value: string | undefined
    }

    const pageCount = () => {
        return notesObj?.pageCount === 0 ? "Verifying..." : notesObj?.pageCount.toString()
    }

    const totalRevenue = () => {
        return notesObj?.totalRevenue ? notesObj?.currency + (notesObj?.totalRevenue / 100).toFixed(2) : "?"
    }

    const averageRating = () => {
        try{
            return notesObj?.averageRating && notesObj?.averageRating > -0.5 ? notesObj?.averageRating.toFixed(1) : "?"
        }catch(error){
            handleErrors(error)
            return "?"
        }
    }

    const infos: labelValuePair[] = [
        { label: "Course Code", value: courseCode() || "N/A" },
        { label: "Semester", value: notesObj?.semester || "N/A" },
        { label: "Institution", value: institution() || "N/A" },
        { label: "Professor", value: notesObj?.prof || "N/A" },
        { label: "Pages", value: pageCount() || "N/A" },
        { label: "Uploader", value: authorDisplayName() || "N/A" },
        { label: "Upload Date", value: createDateString(notesObj?.createdAt) || "N/A" },
    ]

    const infoLabelStyle = (label) => {
        if(label === "Course Code"){
            return `${styles.infoLabel} ${styles.infoLabelCourseCode}`
        }else{
            return styles.infoLabel
        }
    }

    const infoTextStyle = (label) => {
        if (label === "Course Code") {
            return `${styles.infoText} ${styles.infoLabelCourseCode}`
        } else {
            return styles.infoText
        }
    }

    function showRateDialog(){
        if (ownership === "purchased" || notesObj?.price === 0) {
            setIsRateModalActive(true)
        } else {
            setAlertState({
                message: "You must purchase this note to rate it.",
                severity: "warning",
                duration: 6000,
            })
            setIsSnackBarOpen(true)
        }
    }

    return (
        <>
            {notesObj && (
                <Helmet>
                    <title>{`${notesObj.title} - ${courseCode()} ${institution()} ${notesObj?.prof} ${
                        notesObj.semester
                    } | NoteTaking.Club`}</title>
                    <meta
                        name="description"
                        content={`Buy${institution()} ${courseCode()}${notesObj.semester} ${notesObj.prof} course notes - "${
                            notesObj.title
                        }" uploaded by ${authorDisplayName()}`}
                    />
                </Helmet>
            )}

            <div className={styles.topLevelContainer}>
                <div className={styles.container}>
                    {ownership === "unknown" ? (
                        <div className={styles.sideBar}></div>
                    ) : (
                        <div className={styles.sideBar}>
                            {width <= 480 && ownership !== "purchased" && notesObj?.price !== 0 && (
                                <Button
                                    onClick={displayPreviewMobile}
                                    variant="outlined"
                                    color="secondary"
                                    className={`${styles.sideBarButton} ${isViewDocDisabled ? styles.loadingButton : ""}`}
                                >
                                    DISPLAY PREVIEW
                                </Button>
                            )}

                            {ownership === "not purchased" && notesObj?.price !== 0 ? (
                                <Button
                                    onClick={processPurchase}
                                    disabled={notesObj?.unlisted === true}
                                    variant="outlined"
                                    className={styles.sideBarButton}
                                    color={"secondary"}
                                >
                                    {notesObj?.unlisted === true ? "Unlisted" : "Purchase"}
                                </Button>
                            ) : (
                                <Button
                                    onClick={displayFullDocument}
                                    variant="outlined"
                                    color="secondary"
                                    className={`${styles.sideBarButton} ${isViewDocDisabled ? styles.loadingButton : ""}`}
                                >
                                    VIEW FULL DOCUMENT
                                </Button>
                            )}
                            {ownership === "authored" && (
                                <div className={`${styles.sideBarTile} ${styles.authorActionContainer}`}>
                                    <div
                                        onClick={() => history.push(`/notes/${id}/edit`)}
                                        className={`${styles.notesActionButton} ${styles.firstAuthorAction}`}
                                    >
                                        <EditOutlined className={styles.authorActionIcon}></EditOutlined>
                                        EDIT
                                    </div>
                                    <div onClick={() => setIsDeleteModalActive(true)} className={`${styles.notesActionButton}`}>
                                        <DeleteOutline className={styles.authorActionIcon}></DeleteOutline>
                                        DELETE
                                    </div>
                                    {deleteDialog()}
                                    {notesObj?.unlisted === false ? (
                                        <div
                                            onClick={() => updateUnlistedValue(true)}
                                            className={`${styles.notesActionButton} ${styles.lastAuthorAction}`}
                                        >
                                            <VisibilityOffOutlined className={styles.authorActionIcon}></VisibilityOffOutlined>
                                            UNLIST
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => updateUnlistedValue(false)}
                                            className={`${styles.notesActionButton} ${styles.lastAuthorAction}`}
                                        >
                                            <VisibilityOutlined className={styles.authorActionIcon}></VisibilityOutlined>
                                            LIST
                                        </div>
                                    )}
                                </div>
                            )}
                            {ownership === "authored" && (
                                <div className={`${styles.sideBarTile} ${styles.salesContainer}`}>
                                    <div className={styles.salesInfoContainer}>
                                        <div className={styles.infoLabelContainer}>
                                            <div className={styles.infoLabel}>Purchases</div>
                                            <div className={styles.infoLabel}>Total Revenue</div>
                                        </div>
                                        <div className={styles.infoTextContainer}>
                                            <div className={styles.infoText}>{notesObj?.purchases}</div>
                                            <div className={styles.infoText}>{totalRevenue()}</div>
                                        </div>
                                    </div>
                                    <div className={styles.walletButton}>
                                        <a className={styles.walletButton} onClick={handleOpenStripeDash}>
                                            {" "}
                                            VIEW WALLET{" "}
                                        </a>{" "}
                                    </div>
                                </div>
                            )}
                            <div className={`${styles.sideBarTile}`}>
                                <div className={`${styles.titleLabel} ${styles.userGenLabel}`}>Title</div>
                                <div className={styles.userGenText}> {notesObj?.title} </div>
                                <div className={`${styles.descriptionLabel} ${styles.userGenLabel}`}>Description</div>
                                <div className={styles.userGenText}> {notesObj?.description} </div>
                            </div>
                            <div className={`${styles.sideBarTile} ${styles.topLevelInfoContainer}`}>
                                <div className={styles.infoContainer}>
                                    <div className={styles.infoLabelContainer}>
                                        {infos.map((value) => (
                                            <div className={infoLabelStyle(value.label)}>{value.label}</div>
                                        ))}
                                    </div>
                                    <div className={styles.infoTextContainer}>
                                        {infos.map((value) => (
                                            <div className={infoTextStyle(value.label)}>{value.value}</div>
                                        ))}
                                    </div>
                                </div>
                                <Divider className={styles.divider} />
                                <div className={styles.bottomSection}>
                                    <div className={styles.ratingContainer}>
                                        {notesObj?.ratings !== undefined && notesObj.ratings > 0 && (
                                            <>
                                                <div className={styles.rating}>{averageRating()}</div>
                                                <div className={styles.ratingDenominator}>/5</div>
                                            </>
                                        )}
                                        <div className={styles.numberOfRatings}>{`${notesObj?.ratings} ratings`}</div>
                                    </div>
                                    <div className={styles.price}>
                                        {notesObj?.currency}
                                        {priceToString(notesObj?.price ?? 0)}
                                    </div>
                                </div>
                            </div>
                            {ownership !== "authored" && (
                                <div className={`${styles.sideBarTile} ${styles.authorActionContainer}`}>
                                    <div
                                        onClick={showRateDialog}
                                        className={`${styles.notesActionButton} ${styles.firstAuthorAction} ${styles.notAuthoredActionButton}`}
                                    >
                                        <GradeOutlined className={styles.authorActionIcon}></GradeOutlined>
                                        RATE NOTES
                                    </div>
                                    {rateDialog()}
                                    <div
                                        onClick={() => history.push(`/report-notes/${id}`)}
                                        className={`${styles.notesActionButton} ${styles.notAuthoredActionButton}`}
                                    >
                                        <FlagOutlined className={styles.authorActionIcon}></FlagOutlined>
                                        REPORT/ REFUND
                                    </div>
                                    <div
                                        onClick={() => setIsContactModalActive(true)}
                                        className={`${styles.notesActionButton} ${styles.notAuthoredActionButton} ${styles.lastAuthorAction}`}
                                    >
                                        <MessageOutlined className={styles.authorActionIcon}></MessageOutlined>
                                        CONTACT SELLER
                                    </div>
                                    {contactDialog()}
                                </div>
                            )}
                        </div>
                    )}
                    {width > 480 && (
                        <div>
                            {previewUrlDownloaded() && (
                                <Document
                                    file={previewURL}
                                    loading={() => setPreviewLoadState(PREVIEW_LOADING_MSG)}
                                    noData={() => setPreviewLoadState(PREVIEW_NO_DATA_MSG)}
                                    error={() => setPreviewLoadState(PREVIEW_FAILED_MSG)}
                                    onLoadSuccess={({ numPages }) => onPreviewLoad(numPages)}
                                >
                                    {displayPreviewPages()}

                                    <div className={styles.endOfPreviewMessage}>
                                        {ownership === "authored" || ownership === "purchased" || notesObj?.price === 0
                                            ? `End of preview. Scroll to the top to view the full notes.`
                                            : `End of preview. Purchase to view the rest (${notesObj?.pageCount} pages).`}
                                    </div>
                                </Document>
                            )}
                        </div>
                    )}

                    {width > 480 && previewLoadState !== PREVIEW_FAILED_MSG && previewLoadState !== PREVIEW_SUCCESS_MSG && (
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            className={styles.previewSkeleton}
                            width={calculatePageWidth()}
                            height={calculatePageHeight()}
                        />
                    )}

                    {width > 480 && previewLoadState === PREVIEW_FAILED_MSG && (
                        <div className={styles.previewSkeleton} style={{ width: calculatePageWidth(), height: calculatePageHeight() }} />
                    )}
                </div>
                <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration} onClose={closeSnackbar}>
                    <MuiAlert onClose={closeSnackbar} severity={alertState.severity} sx={{ width: "100%" }}>
                        {alertState.message}
                    </MuiAlert>
                </Snackbar>
            </div>
        </>
    )
}
export default Notes