import React, { useState } from 'react'
import styles from '../styles/dashboard.module.css'
import DashboardGrid from '../components/DashboardGrid';
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import Box from "@mui/material/Box";
import {StyledTab, StyledTabs} from "../components/DashTabs";
import {Helmet} from 'react-helmet'
import { IAlert } from '../models/IAlert';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert"

const Dashboard: React.FC = () => {
    const history = useHistory<History>()
    const tabs = ["viewed-notes", "purchased-notes", "uploaded-notes"]
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(
        history.location.state && history.location.state[`isSnackBarOpen`] ? history.location?.state[`isSnackBarOpen`] : false
    )
    const [alertState, setAlertState] = useState<IAlert>(
        history.location.state && history.location?.state[`alert`]
            ? history.location?.state[`alert`]
            : {
                  message: "",
                  severity: "success",
                  duration: 6000,
              }
    )


    interface ParamTypes {
        activeTab: string;
    }

    const { activeTab } = useParams<ParamTypes>();

    const handleItemClick = (event: React.SyntheticEvent, newValue: number) => {
        history.push(`/dashboard/${tabs[newValue]}`)
    }

    function tabNumber(){
        switch (activeTab) {
            case "uploaded-notes":
                return 2
            case "purchased-notes":
                return 1
            default:
                return 0;
        }
    }

    return (
        <>
            <Helmet>
                <title>Dashboard | NoteTaking.Club</title>
            </Helmet>
            <div>
                <div className={styles.tabsContainer}>
                    <Box>
                        <StyledTabs value={tabNumber()} onChange={handleItemClick} aria-label="">
                            <StyledTab label="Viewed" />
                            <StyledTab label="Purchased" />
                            <StyledTab label="Uploaded" />
                        </StyledTabs>
                    </Box>
                </div>
                <DashboardGrid activeTab={tabs.includes(activeTab) ? activeTab : "viewed-notes"} />
            </div>
            <Snackbar open={isSnackBarOpen} autoHideDuration={alertState.duration} onClose={() => setIsSnackBarOpen(false)}>
                <MuiAlert onClose={() => setIsSnackBarOpen(false)} severity={alertState.severity} sx={{ width: "100%" }}>
                    {alertState.message}
                </MuiAlert>
            </Snackbar>
        </>
    )
}
export default Dashboard