import React, { useState, useEffect } from "react"
import { INotes, priceToString } from "../models/INotes"
import styles from "../styles/notethumb.module.css"
import notesStyles from "../styles/notes.module.css"
import Card from "@mui/material/Card"
import CardActionArea from "@mui/material/CardActionArea"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import { ThumbUpAltOutlined } from "@material-ui/icons"
import { useHistory } from "react-router"
import Skeleton from "@mui/material/Skeleton"
import { getStorage, ref, getDownloadURL } from "firebase/storage"

const NotesThumbnail: React.FC<{ notes: INotes; loading: boolean }> = (props) => {
    const [thumbURL, setThumbURL] = useState("")
    const history = useHistory()
    const storage = getStorage()
    const { notes } = props

    useEffect(() => {
        if (!notes.id) return 

        const thumbPath = `notes/${props.notes.authorId}/${props.notes.id}/thumbnail.jpeg`
        const thumbnailStorageRef = ref(storage, thumbPath)
        getDownloadURL(thumbnailStorageRef).then((url) => {
            setThumbURL(url)
        }).catch((error) => {
            console.log(error)
        })
    }, [notes])

    const institution = () => {
        return props.notes.institution || "N/A"
    }

    const courseCode = () => {
        return props.notes.courseCode || "N/A"
    }

    const handleCardClick = () => {
        if (props.loading) return
        history.push(`/notes/${props.notes.id}`)
    }

    const averageRating = () => {
        try {
            return props.notes.averageRating && props.notes.averageRating > -0.5 ? props.notes.averageRating.toFixed(1) : "?"
        } catch (error) {
            return "?"
        }
    }

    return (
        <Card className={styles.card} onClick={handleCardClick}>
            <CardActionArea className={styles.actionArea}>
                {props.loading ? (
                    <Skeleton className={styles.thumbnailSkeleton} animation="wave" variant="rectangular" />
                ) : (
                    <CardMedia component="img" height="144px" image={thumbURL} alt="" className={styles.thumbnail} />
                )}
                {props.loading ? (
                    <CardContent className={styles.cardContentSkeleton}>
                        <Skeleton animation="wave" className={`${styles.lineSkeleton} ${styles.schoolSkeleton}`} />
                        <Skeleton animation="wave" className={styles.lineSkeleton} />
                        <Skeleton animation="wave" className={styles.lineSkeleton} />
                    </CardContent>
                ) : (
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            {institution()}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="div" color="text.primary">
                            {courseCode()} - {props.notes.semester}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" className={`${styles.notesTitle}`}>
                            {props.notes.title}
                        </Typography>
                    </CardContent>
                )}

                <Divider variant="middle" />
                {props.loading ? (
                    <CardActions disableSpacing>
                        <ThumbUpAltOutlined className={`${styles.stats} ${styles.icon}`} style={{ opacity: 0 }} />
                    </CardActions>
                ) : (
                    <CardActions disableSpacing>
                        <div className={`${styles.ratingContainer}`}>
                            {props.notes.ratings > 0 && (
                                <>
                                    <div className={notesStyles.rating}>{averageRating()}</div>
                                    <div className={notesStyles.ratingDenominator}>/5</div>
                                </>
                            )}
                            <div className={notesStyles.numberOfRatings}>{`${props.notes?.ratings} ratings`}</div>
                        </div>
                        <div className={styles.price}>
                            {props.notes.currency}
                            {priceToString(props.notes.price)}
                        </div>
                    </CardActions>
                )}
            </CardActionArea>
        </Card>
    )
}

export default NotesThumbnail
